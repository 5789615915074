<template>
  <div ref="threeContainer" class="three-container" @mousemove="handleMouseMove">

  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default {
  name: 'ThreeJSComponent',
  data() {
    return {
      showMenu: false,
      menuX: 0,
      menuY: 0,
      camera: null // 将 camera 声明为组件的属性
    };
  },
  mounted() {
    this.initThreeJS();
  },
  methods: {
    initThreeJS() {
      const container = this.$refs.threeContainer;
      const scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000); // 将 camera 赋值给组件的属性
      this.camera.position.z = 5;
      this.camera.position.x = 2;
      this.camera.position.y = 2;
      this.camera.lookAt(0, 0, 0);

      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(container.clientWidth, container.clientHeight);
      renderer.setClearColor(0x111c35);
      container.appendChild(renderer.domElement);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const loader = new GLTFLoader();
      loader.load(
          '/model/scene2/scene.gltf',
          (gltf) => {
            const model = gltf.scene;
            model.scale.set(10, 10, 10);
            model.position.y += 0;
            scene.add(model);
            // 记录模型以便后面的鼠标碰撞检测
            this.model = model;
          },
          undefined,
          (error) => {
            console.error('Failed to load model', error);
          }
      );

      const gridHelper = new THREE.GridHelper(10, 10, 0x0000ff, 0x0000ff);
      scene.add(gridHelper);

      const controls = new OrbitControls(this.camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.screenSpacePanning = false;

      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, this.camera); // 使用 this.camera 替换 camera
      };

      animate();
    },
    handleMouseMove(event) {
      const container = this.$refs.threeContainer;
      const containerRect = container.getBoundingClientRect();
      const mouse = {
        x: ((event.clientX - containerRect.left) / containerRect.width) * 2 - 1,
        y: -((event.clientY - containerRect.top) / containerRect.height) * 2 + 1
      };

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, this.camera); // 使用 this.camera 替换 camera

      const intersects = raycaster.intersectObject(this.model, true);
      if (intersects.length > 0) {
        this.showMenu = true;
        this.menuX = event.clientX;
        this.menuY = event.clientY;
      } else {
        this.showMenu = false;
      }
    }
  }
};
</script>

<style>
.three-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu {
  position: fixed;
  //background-color: white;
  //border: 1px solid black;
  padding: 10px;
}
</style>
