<template>
  <div ref="chart" class="chart-container"></div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import * as echarts from 'echarts'

export default defineComponent({
  name: 'RevenueBarChart',
  setup() {
    const chart = ref(null)

    onMounted(() => {
      // 初始化图表
      const myChart = echarts.init(chart.value)

      // 模拟数据
      const months = ['2024年1月', '2024年2月', '2024年3月', '2024年4月', '2024年5月', '2024年6月', '2024年7月', '2024年8月', '2024年9月', '2024年10月', '2024年11月', '2024年12月']
      const revenueData = [5000, 6000, 5500, 6200, 5800, 6000, 6500, 6800, 6700, 7200, 7100, 7400]

      // 设置图表的配置项
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['营收']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: months,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} 元'
          }
        },
        series: [
          {
            name: '营收',
            type: 'bar',
            data: revenueData,
            itemStyle: {
              color: '#23cefd'
            },
            barWidth: '30%'  // 调整柱子的宽度
          }
        ]
      }

      // 使用配置项设置图表
      myChart.setOption(option)

      // 处理窗口大小变化
      window.addEventListener('resize', myChart.resize)
    })

    return {
      chart
    }
  }
})
</script>

<style scoped>
.chart-container {
  width: 100%;
  height:90%;
}
</style>
