<template>
  <div class="production-schedule">
    <h1 class="title">试验项目</h1>
    <div class="toolbar">
      <button @click="showAddModal = true">添加项目</button>
      <input v-model="searchQuery" placeholder="搜索任务" />
    </div>
    <table class="schedule-table">
      <thead>
      <tr>
        <th>ID</th>
        <th>项目名称</th>
        <th>开始时间</th>
        <th>当前阶段</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="task in filteredTasks" :key="task.id">
        <td>{{ task.id }}</td>
        <td>{{ task.name }}</td>
        <td>{{ task.startTime }}</td>
        <td>{{ task.status }}</td>
        <td>
          <button @click="editTask(task)">编辑</button>
          <button @click="deleteTask(task.id)">删除</button>
        </td>
      </tr>
      </tbody>
    </table>
    <CustomModal v-if="showAddModal" @close="showAddModal = false">
      <h2 slot="header">添加任务</h2>
      <div slot="body">
        <form @submit.prevent="addTask">
          <label>任务名称: <input v-model="newTask.name" required /></label>
          <label>开始时间: <input v-model="newTask.startTime" type="datetime-local" required /></label>
          <label>状态:
            <input v-model="newTask.status" required />
          </label>
          <button type="submit">添加</button>
        </form>
      </div>
    </CustomModal>

  </div>
</template>

<script>
import CustomModal from './CustomModal.vue';
import * as echarts from 'echarts';

export default {
  name: 'ProductionSchedule',
  components: {
    CustomModal
  },
  data() {
    return {
      tasks: [
        { id: 1, name: 'P202005', startTime: '2024-06-01T08:00',  status: '处方工艺接近尾声' },
        { id: 2, name: 'P202208', startTime: '2024-06-01T13:00',  status: '已完成稳定性6个月，准备提交申报资料' },
        { id: 3, name: 'P202314', startTime: '2024-06-02T08:00',  status: '处方研究过程中' },
        { id: 4, name: 'P202107', startTime: '2024-06-02T08:00',  status: '已完成工艺验证，稳定性试验进行中' },
        { id: 5, name: 'P202214', startTime: '2024-06-02T08:00',  status: '已完成工艺验证，稳定性试验进行中' },
        { id: 6, name: 'P202303', startTime: '2024-06-02T08:00',  status: '处方工艺研究过程中' },
        { id: 7, name: 'P202311', startTime: '2024-06-02T08:00',  status: '处方工艺研究过程中' },
        { id: 8, name: 'P202407 ', startTime: '2024-06-02T08:00', status: '处方工艺研究过程中' },
        { id: 9, name: 'P202408', startTime: '2024-06-02T08:00',  status: '处方工艺研究过程中' },
      ],
      showAddModal: false,
      newTask: {
        name: '',
        startTime: '',
        endTime: '',
        status: '未开始'
      },
      searchQuery: ''
    };
  },
  computed: {
    filteredTasks() {
      return this.tasks.filter(task =>
          task.name.includes(this.searchQuery)
      );
    }
  },
  methods: {
    addTask() {
      const newId = this.tasks.length ? Math.max(...this.tasks.map(t => t.id)) + 1 : 1;
      const newTask = { ...this.newTask, id: newId };
      this.tasks.push(newTask);
      this.showAddModal = false;
      this.newTask = { name: '', startTime: '', endTime: '', status: '未开始' };
    },
    editTask() {
      // 编辑任务的逻辑
    },
    deleteTask(id) {
      this.tasks = this.tasks.filter(t => t.id !== id);
    },
    renderProgressChart() {
      const progressChart = echarts.init(this.$refs.progressChart);
      const progressOption = {
        title: {
          text: '生产进度',
          left: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 10,
          data: this.tasks.map(task => task.name)
        },
        series: [
          {
            name: '生产进度',
            type: 'pie',
            radius: '50%',
            center: ['50%', '60%'],
            data: this.tasks.map(task => ({ value: 1, name: task.name })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      progressChart.setOption(progressOption);
    }
  },
  mounted() {
    this.renderProgressChart();
  }
};
</script>

<style scoped>
.production-schedule {
  background-color: #12142b;
  color: white;
  padding: 20px;
  border-radius: 8px;
  height: 100%;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.toolbar {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.schedule-table th, .schedule-table td {
  border: 1px solid white;
  padding: 10px;
  text-align: left;
}

.schedule-table th {
  background-color: #343a40;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

form label {
  display: block;
  margin-bottom: 10px;
}

form input, form select {
  width: calc(100% - 20px);
  padding: 5px;
  margin-bottom: 20px;
}

.echarts-container {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}

.echarts {
  width: 100%;
  height: 100%;
}
</style>
