<template>
  <div class="approval-process">
    <div class="btns">
      <div class="list-title">党建活动</div>
    </div>
    <div class="main-box" style="height: 2vh">
      <div class="left">
      </div>
    </div>
    <div class="block">
      <el-timeline>
        <el-timeline-item :timestamp="timestampToDate(item.date1) " placement="top" v-for="(item,index) in list" :key="index">
          <el-card>
            <h4>{{ item.title }} ——{{item.type }}</h4>
            <h4>{{ item.state }}</h4>
            <p>{{ timestampToDate(item.date1) }} <el-link type="primary" @click="kantie(item)">查看</el-link>|<el-link type="info" disabled>签到</el-link></p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>

    <el-dialog :title="Tie.title" :visible.sync="showTie" width="30%">
        <div>{{Tie.user}}{{timestampToDate(Tie.date1) }}</div>
        <div v-html="Tie.content" class="tiezi"></div>

    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'DangJian',
  components: {

  },
  data() {
    return {
      value:new Date(),
      showTie:false,
      Tie:{},
      applymodel: false,
      form: {
        type: '',
        title: '',
        content: '',
        date1: '',
        state: '已结束',
      },
      list: [
        {
          type: '党支部1',
          title: '4月民主生活会',
          content: '4月民主生活会的会议内容',
          date1: '1712804469000',
          state: '已结束',
        },
        {
          type: '党支部1',
          title: '党课开始',
          content: '党课开始党课开始党课开始党课开始',
          date1: '1711329989000',
          state: '已结束',
        },
        {
          type: '党支部1',
          title: '三会一课会议召开',
          content: '三会一课会议召开三会一课会议召开',
          date1: '1710990069000',
          state: '已结束',
        },
        {
          type: '党支部1',
          title: '学习强国成果检验',
          content: '学习强国成果检验学习强国成果检验学习强国成果检验',
          date1: '1711329989000',
          state: '已结束',
        },

      ],
    };
  },
  methods: {
    kantie(arr){
      this.Tie = arr;
      this.showTie = true
    },
    submit() {
      this.list.unshift(this.form)
      this.applymodel = false
      this.form = {
        type: '',
        title: '',
        content: '',
        date1: '',
        user: '张三',
      }
    },
    timestampToDate(timestamp) {
      timestamp = Number(timestamp);
      var date = new Date(timestamp); // 时间戳转换成Date对象
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，需要+1因为月份从0开始
      var day = date.getDate(); // 获取日
      var hours = date.getHours(); // 获取小时
      var minutes = date.getMinutes(); // 获取分钟
      var seconds = date.getSeconds(); // 获取秒钟

      // 格式化月份、日期、小时、分钟和秒
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 组合成日期时间字符串
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
  },
}
</script>

<style scoped>
/deep/ .el-dialog {
  background: #1e1e2e !important;
  color: #fff !important;
}

/deep/ .el-dialog__title {
  color: #fff !important;
}
/deep/.el-card{
  background-color: #d2e4e9;
}
.main-box{
  display: flex;
  .left{
    width: 30%;
  }
}
.tiezi{
  margin-top: 2vh;
  color: #fff ;
  padding: 1vw;
  width: 80%;
}
.tiezi img{
  width: 80% !important;
}
.list {
  flex: 1;
  .head {
    font-weight: bold;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0c929f;
    text-align: center;

    > div {
      flex: 1;
      text-align: center;
      padding: 2rem;
    }
  }

}

.btns {
  display: flex;
  justify-content: space-between;

  .list-title {
    font-size: 1.5rem;
  }
}

.nodata {
  text-align: center;
  padding-top: 10vh;

  img {
    width: 5vw;
  }
}

.fun-btn {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/images/bgimg/funbtn.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 1vw;
  padding: 0.2vw 1vw;
}
.is-selected {
  color: #1989FA;
}
</style>
