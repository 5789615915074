<template>
  <div ref="pieChart" style="width: 100%; height: 100%;  "></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      // 数据
      chartData: [
        { value: 335, name: '工艺路线' },
        { value: 310, name: '处方研究' },
        { value: 234, name: '工艺开发' },
        { value: 135, name: '稳定性实验' },
        { value: 1548, name: '小试阶段' },
        { value: 200, name: '已获文号',itemStyle: {color: '#7e838d'}},
      ]
    };
  },
  mounted() {
    this.renderPieChart();
  },
  methods: {
    renderPieChart() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.pieChart);

      // 配置项
      const option = {
        backgroundColor: 'rgba(0,0,0,0)', // 设置背景为透明
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: 'Pie Chart',
            type: 'pie',
            data: this.chartData
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表
      myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
/* 可以在这里定义样式 */
</style>
