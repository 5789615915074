<template>
  <div ref="chart" class="chart-container"></div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import * as echarts from 'echarts'

export default defineComponent({
  name: 'FactoryCapacityChart',
  setup() {
    const chart = ref(null)

    onMounted(() => {
      // 初始化图表
      const myChart = echarts.init(chart.value)

      // 生成过去30天的日期数据
      const generateDates = (days) => {
        const dates = []
        const today = new Date()
        for (let i = days - 1; i >= 0; i--) {
          const date = new Date(today)
          date.setDate(today.getDate() - i)
          dates.push(`${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`)
        }
        return dates
      }

      // 模拟数据
      const dates = generateDates(30)
      const currentYearData = Array.from({ length: 30 }, (_, i) => 1000 + Math.random() * 200 + i * 10)
      const lastYearData = Array.from({ length: 30 }, (_, i) => 950 + Math.random() * 200 + i * 5)

      // 设置图表的配置项
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['上个周期产能', '本周期产能']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dates
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}吨'
          }
        },
        series: [
          {
            name: '上个周期产能',
            type: 'line',
            stack: '总量',
            data: currentYearData
          },
          {
            name: '本周期产能',
            type: 'line',
            stack: '总量',
            data: lastYearData
          }
        ]
      }

      // 使用配置项设置图表
      myChart.setOption(option)

      // 处理窗口大小变化
      window.addEventListener('resize', myChart.resize)
    })

    return {
      chart
    }
  }
})
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 90%;
}
</style>
