<template>
  <div class="approval-process">
    <div class="btns">
      <div class="list-title">供应商信息</div>
      <div>

        <el-dialog title="新增" :visible.sync="applymodel" width="30%">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="项目名称">
              <el-input v-model="form.data1"></el-input>
            </el-form-item>
            <el-form-item label="委托研发单位">
              <el-input v-model="form.data2"></el-input>
            </el-form-item>
            <el-form-item label="期限">
              <el-input v-model="form.data3"></el-input>
            </el-form-item>
            <el-form-item label="有效起止时间">
              <el-input v-model="form.data4"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
              <el-button @click="applymodel=false">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>

    </div>
    <div class="list">
      <div class="item head">
        <div>供应商编码</div>
        <div>供应商名称</div>
        <div>供应商地址</div>
      </div>
      <div  class="item" v-for="(item,index) in list" :key="index">
        <div>{{ item.id }}</div>
        <div>{{ item.company }}</div>
        <div>{{ item.address }}</div>
      </div>
    </div>
    <el-dialog :title="Tie.title" :visible.sync="showTie" width="30%">
      <div>{{Tie.user}}{{timestampToDate(Tie.date1) }}</div>
      <div v-html="Tie.content" class="tiezi"></div>

    </el-dialog>
  </div>
</template>

<script>
import zh from '@/quill-zh';  // 引入中文语言包
export default {
  name: 'ApprovalProcess',
  components: {
  },
  data() {
    return {
      showTie:false,
      Tie:{},
      editorOptions: {
        theme: 'snow',
        placeholder: zh.placeholder,
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image']
          ]
        },
        formats: zh.formats  // 设置格式的中文名称
      },

      applymodel: false,
      form: {
        data1:'',
        data2:'',
        data3:'',
        data4:'',
      },
      list: [
        {
          id:'00200046',
          company: "卡陆康（上海）贸易有限公司",
          address: "上海市闵行区漕宝路3223号1幢1层A-105室"
        },
        {
          id:'0020074',
          company: "济南鲁强玻璃仪器有限公司",
          address: "山东省济南市天桥区无影山中路121号天福苑小区B区1号楼1-602室"
        },
        {
          id:'0020148',
          company: "中国食品药品检定研究院",
          address: "北京市东城区天坛西里2号"
        },
        {
          id:'0020362',
          company: "湖州展望药业有限公司",
          address: "浙江省湖州市德清县武康镇春晖街18号"
        },
        {
          id:'0020154',
          company: "上海风泓药用辅料技术有限公司",
          address: "上海市奉贤区海湾旅游区奉炮公路314号1幢109室"
        },
        {
          id:'0020310',
          company: "山东维维安生物科技有限公司",
          address: "山东省济南市天桥区无影山中路48-15号美林大厦西塔2106室"
        },
        {
          id:'0020373',
          company: "南京欣顶医药科技有限公司",
          address: "南京市江北新区生物医药谷加速器二期14号楼7层"
        },
        {
          id:'0020074',
          company: "山东博韦尔生物科技有限公司",
          address: "山东省济南市天桥区无影山中路121号天福苑小区B区2号楼1-602室"
        },
        {
          id:'0020092',
          company: "济南启光科贸有限公司",
          address: "山东省济南市天桥区无影山中路121号天福苑小区B区1号楼1-601室"
        },
        {
          id:'0020096',
          company: "山东跃诚生物科技有限公司",
          address: "山东省济南市天桥区无影山中路121号天福苑小区B区1号楼1-502室"
        },
        {
          id:'0020148',
          company: "奥淇科化医疗供应链管理服务(天津)有限公司",
          address: "天津市河西区洞庭路24号B座302室"
        },
        {
          id:'0020211',
          company: "坛墨质检科技股份有限公司",
          address: "北京市通州区经济开发区东区靓丽三街9号-694"
        },
        {
          id:'0020264',
          company: "济南泉畅商贸有限公司",
          address: "山东省济南市天桥区无影山中路121号天福苑小区B区1号楼1-301室"
        },
        {
          id:'0020279',
          company: "山东科伦医药贸易有限公司",
          address: "山东省济南市天桥区无影山中路121号天福苑小区B区1号楼1-1102室"
        },
        {
          id:'0020314',
          company: "福州新北生化工业有限公司",
          address: "福州市闽侯县上街镇马保村"
        },
        {
          id:'0020322',
          company: "安徽泽升科技股份有限公司",
          address: "安徽省合肥市长丰县双凤工业区梅冲湖路与文明路交口西南角"
        },
        {
          id:'0020339',
          company: "罗辅医药科技（上海）有限公司",
          address: "上海市浦东新区泥城镇秋兴路875号7幢3层"
        },
        {
          id:'0020352',
          company: "山东睿亦航信息科技有限公司",
          address: "山东省济南市天桥区无影山中路121号天福苑小区B区2号楼1-1002室"
        }
      ],
    };
  },
  methods: {
    kantie(arr){
      this.Tie = arr;
      this.showTie = true
    },
    submit() {
      this.list.unshift(this.form)
      this.applymodel = false
      this.form = {
        data1:'',
        data2:'',
        data3:'',
        data4:'',
      }
    },
    timestampToDate(timestamp) {
      timestamp = Number(timestamp);
      var date = new Date(timestamp); // 时间戳转换成Date对象
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，需要+1因为月份从0开始
      var day = date.getDate(); // 获取日
      var hours = date.getHours(); // 获取小时
      var minutes = date.getMinutes(); // 获取分钟
      var seconds = date.getSeconds(); // 获取秒钟

      // 格式化月份、日期、小时、分钟和秒
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 组合成日期时间字符串
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
  },
}
</script>

<style scoped>
/deep/ .el-dialog {
  background: #1e1e2e !important;
  color: #fff !important;
}

/deep/ .el-dialog__title {
  color: #fff !important;
}
.tiezi{
  margin-top: 2vh;
  color: #fff ;
  padding: 1vw;
  width: 80%;
}
.tiezi img{
  width: 80% !important;
}
.list {
  height: 55vh;
  overflow-y: auto;
  .head {
    font-weight: bold;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0c929f;
    text-align: center;
    cursor: pointer;

    > div {
      flex: 1;
      text-align: center;
      padding: 2rem;
    }
  }
  .item:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.btns {
  display: flex;
  justify-content: space-between;

  .list-title {
    font-size: 1.5rem;
  }
}

.nodata {
  text-align: center;
  padding-top: 10vh;

  img {
    width: 5vw;
  }
}

.fun-btn {
  cursor: pointer;
  display: inline-block;
  background: url("../../assets/images/bgimg/funbtn.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 1vw;
  padding: 0.2vw 1vw;
}
</style>
