import { render, staticRenderFns } from "./RevenueBarChart.vue?vue&type=template&id=5214602c&scoped=true"
import script from "./RevenueBarChart.vue?vue&type=script&lang=js"
export * from "./RevenueBarChart.vue?vue&type=script&lang=js"
import style0 from "./RevenueBarChart.vue?vue&type=style&index=0&id=5214602c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5214602c",
  null
  
)

export default component.exports