<template>
  <div class="animated-number">
    <span>{{ formattedNumber }}</span>
  </div>
</template>

<script>
export default {
  props: {
    initialValue: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      displayNumber: 0,
      intervalId: null
    };
  },
  computed: {
    formattedNumber() {
      // 将数字格式化为三位数，不足的前面补零
      return this.formatNumber(this.displayNumber);
    }
  },
  watch: {
    initialValue: 'startAnimation'
  },
  mounted() {
    this.startAnimation();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    startAnimation() {
      const duration = 1500;
      const start = Date.now();
      const endValue = this.initialValue;

      const animate = () => {
        const now = Date.now();
        const elapsed = Math.min((now - start) / duration, 1);
        this.displayNumber = Math.floor(elapsed * endValue);
        if (elapsed < 1) {
          requestAnimationFrame(animate);
        } else {
          this.startRandomIncrement();
        }
      };

      requestAnimationFrame(animate);
    },
    startRandomIncrement() {
      this.intervalId = setInterval(() => {
        const increment = Math.floor(Math.random() * 2) + 1;
        const newValue = this.displayNumber + increment;
        this.animateNumber(this.displayNumber, newValue);
      }, Math.floor(Math.random() * 3000) + 2000);
    },
    animateNumber(start, end) {
      const duration = 1000;
      const startTime = Date.now();

      const animate = () => {
        const now = Date.now();
        const elapsed = Math.min((now - startTime) / duration, 1);
        this.displayNumber = Math.floor(elapsed * (end - start) + start);
        if (elapsed < 1) {
          requestAnimationFrame(animate);
        } else {
          this.displayNumber = end;
        }
      };

      requestAnimationFrame(animate);
    },
    formatNumber(num) {
      // 将数字格式化为三位数，不足的前面补零
      return num.toString().padStart(3, '0');
    }
  }
};
</script>
<style scoped>
.animated-number {
  color: #23cefd;
  font-size: 2vw;
  font-weight: bold;
}
</style>
