<template>
  <div class="approval-process">
    <div class="btns">
      <div class="list-title">企业论坛</div>
      <div>
        <div class="fun-btn" @click="applymodel=true">我要发帖</div>
        <el-dialog title="编辑帖子" :visible.sync="applymodel" width="30%">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="板块">
              <el-select v-model="form.type" placeholder="请选择">
                <el-option label="公告" value="公告"></el-option>
                <el-option label="资料共享" value="资料共享"></el-option>
                <el-option label="企业活动" value="企业活动"></el-option>
                <el-option label="闲聊" value="闲聊"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标题">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="帖子">
              <div style="background-color:#fff;height: 25vh">
                <quill-editor
                    v-model="form.content"
                    ref="myQuillEditor"
                    :options="editorOptions"
                    style="height: 20vh"
                ></quill-editor>
              </div>
              
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
              <el-button @click="applymodel=false">取消</el-button>
            </el-form-item>
          </el-form>

        </el-dialog>
      </div>

    </div>
    <div class="list">
      <div class="item head">
        <div>板块</div>
        <div>标题</div>
        <div>时间</div>
        <div>作者</div>
      </div>
      <div @click="kantie(item)" class="item" v-for="(item,index) in list" :key="index">
        <div>{{ item.type }}</div>
        <div>{{ item.title }}</div>
        <div>{{ timestampToDate(item.date1) }}</div>
        <div>{{ item.user }}</div>
      </div>
    </div>
    <el-dialog :title="Tie.title" :visible.sync="showTie" width="30%">
        <div>{{Tie.user}}{{timestampToDate(Tie.date1) }}</div>
        <div v-html="Tie.content" class="tiezi"></div>

    </el-dialog>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import zh from '@/quill-zh';  // 引入中文语言包
export default {
  name: 'ApprovalProcess',
  components: {
    quillEditor,

  },
  data() {
    return {
      showTie:false,
      Tie:{},
      editorOptions: {
        theme: 'snow',
        placeholder: zh.placeholder,
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image']
          ]
        },
        formats: zh.formats  // 设置格式的中文名称
      },
      applymodel: false,
      form: {
        type: '',
        title: '',
        content: '',
        date1: '',
        user: '张三',
      },
      list: [
        {
          type: '公告',
          title: '关于公司国庆节放假的决定',
          content: '现将2024年国庆节放假安排通知如下：\n' +
              '\n' +
              '　　10月1日（星期一）放假，放假七天。\n' +
              '\n' +
              '　　请同事们提前安排好工作生活，节日期间注意安全，度过一个平安、祥和的节日假期。',
          date1: '1727492469000',
          user: '管理员',
        },
        {
          type: '公告',
          title: '关于公司端午节放假的决定',
          content: '现将2024年端午节放假安排通知如下：\n' +
              '\n' +
              '　　6月10日（星期一）放假，与周末连休。\n' +
              '\n' +
              '　　请同事们提前安排好工作生活，节日期间注意安全，度过一个平安、祥和的节日假期。',
          date1: '1717903441000',
          user: '管理员',
        }

      ],
    };
  },
  methods: {
    kantie(arr){
      this.Tie = arr;
      this.showTie = true
    },
    submit() {
      this.list.unshift(this.form)
      this.applymodel = false
      this.form = {
        type: '',
        title: '',
        content: '',
        date1: '',
        user: '张三',
      }
    },
    timestampToDate(timestamp) {
      timestamp = Number(timestamp);
      var date = new Date(timestamp); // 时间戳转换成Date对象
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，需要+1因为月份从0开始
      var day = date.getDate(); // 获取日
      var hours = date.getHours(); // 获取小时
      var minutes = date.getMinutes(); // 获取分钟
      var seconds = date.getSeconds(); // 获取秒钟

      // 格式化月份、日期、小时、分钟和秒
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 组合成日期时间字符串
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
  },
}
</script>

<style scoped>
/deep/ .el-dialog {
  background: #1e1e2e !important;
  color: #fff !important;
}

/deep/ .el-dialog__title {
  color: #fff !important;
}
.tiezi{
  margin-top: 2vh;
  color: #fff ;
  padding: 1vw;
  width: 80%;
}
.tiezi img{
  width: 80% !important;
}
.list {
  .head {
    font-weight: bold;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0c929f;
    text-align: center;
    cursor: pointer;

    > div {
      flex: 1;
      text-align: center;
      padding: 2rem;
    }
  }
  .item:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.btns {
  display: flex;
  justify-content: space-between;

  .list-title {
    font-size: 1.5rem;
  }
}

.nodata {
  text-align: center;
  padding-top: 10vh;

  img {
    width: 5vw;
  }
}

.fun-btn {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/images/bgimg/funbtn.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 1vw;
  padding: 0.2vw 1vw;
}
</style>
