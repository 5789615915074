<template>
  <div id="chart" style="width: 100%; height: 90%;"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "HistogramChart",
  data() {
    return {
      // 湿度数据
      humidityData: [
        65.3, 65, 65.2, 65, 64.9, 65.3, 65.1, 65.3, 65, 65, 65.1, 65.2,
        65, 65.2, 64.9, 65.2, 65.2, 65.3, 65.2, 65.3, 65, 65.1, 65.1,
        65.2, 65.1, 65, 65.2, 65.1, 65.2, 65.2, 65.2, 65.2, 65, 65.1,
        65.2, 65.1, 65.1, 65.2, 65, 65.1, 65.1, 65.2
      ]
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const chart = echarts.init(document.getElementById('chart'));

      // 生成直方图的 bin 区间
      const bins = this.generateBins(this.humidityData, 10);

      const option = {
        title: {
          text: 'YQ-3-063'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          name: 'Humidity (%)',
          type: 'category',
          data: bins.map(bin => `${bin.range[0].toFixed(1)} - ${bin.range[1].toFixed(1)}`),
          axisLabel: {
            rotate: 45
          }
        },
        yAxis: {
          name: 'Frequency',
          type: 'value'
        },
        series: [{
          data: bins.map(bin => bin.count),
          type: 'bar',
          barWidth: '50%',
          itemStyle: {
            color: '#73a0fa'
          }
        }]
      };

      chart.setOption(option);
    },

    // 生成直方图 bin 的函数
    generateBins(data, binCount) {
      const min = Math.min(...data);
      const max = Math.max(...data);
      const binWidth = (max - min) / binCount;

      const bins = Array.from({length: binCount}, (_, i) => ({
        range: [min + i * binWidth, min + (i + 1) * binWidth],
        count: 0
      }));

      // 统计每个 bin 中的数据
      data.forEach(value => {
        for (let i = 0; i < bins.length; i++) {
          if (value >= bins[i].range[0] && value < bins[i].range[1]) {
            bins[i].count++;
            break;
          }
        }
      });

      return bins;
    }
  }
};
</script>

<style scoped>
#chart {
  width: 100%;
  height: 400px;
}
</style>
