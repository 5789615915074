const zh = {
    'formats': {
        'bold': '加粗',
        'italic': '斜体',
        'underline': '下划线',
        'strike': '删除线',
        'blockquote': '引用',
        'code-block': '代码块',
        'header': '标题',
        'indent': '缩进',
        'list': '列表',
        'bullet': '项目符号',
        'ordered': '有序列表',
        'align': '对齐方式',
        'color': '字体颜色',
        'background': '背景颜色',
        'link': '链接',
        'image': '图片',
        'video': '视频',
    },
    'placeholder': '撰写一篇史诗...'
};

export default zh;
