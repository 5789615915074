<template>
  <div ref="chart" style="width: 100%; height: 100%;"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'LineChart',
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.chart);

      const option = {
        title: {
          text: '温度和相对湿度变化图'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['温度', '相对湿度']
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [
            '2-8:24', '2-12:35', '3-8:14', '3-12:25', '4-8:17', '4-12:28',
            '5-8:16', '5-12:27', '6-8:16', '6-12:27', '7-8:48', '7-12:59',
            '9-8:17', '9-12:28', '10-8:46', '10-12:57', '11-8:18', '11-12:29',
            '12-8:16', '12-12:27', '13-8:45', '13-12:56', '14-8:24', '14-12:35',
            '18-8:18', '18-12:29', '19-8:11', '19-12:22', '20-8:12', '20-12:23',
            '21-8:15', '21-12:26', '23-8:23', '23-12:34', '24-8:18', '24-12:29',
            '25-8:48', '25-12:59', '26-8:17', '26-12:28', '27-8:46', '27-12:57',
            '28-8:19', '28-12:30', '29-8:22', '29-12:33', '30-8:24', '30-12:35'
          ]
        },
        yAxis: [
          {
            type: 'value',
            name: '温度 (°C)',
            position: 'left',
            min: 24,
            max: 28,
            axisLabel: {
              formatter: '{value} °C'
            }
          },
          {
            type: 'value',
            name: '相对湿度 (%)',
            position: 'right',
            min: 40,
            max: 60,
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: '温度',
            type: 'line',
            stack: '总量',
            data: [
              null, 25.6, 25.9, 26.0, 26.3, 26.3, 26.5, 26.8, 26.4, 26.7, 26.4, 26.7,
              26.4, 26.7, 26.5, 26.8, 25.5, 25.8, 25.5, 25.8, 26.0, 26.3, 25.6, 25.9,
              25.5, 25.8, 26.0, 26.3, 25.0, 25.3, 26.5, 26.8, 26.8, 27.1, 26.4, 26.7,
              26.4, 26.7, 26.5, 26.8, 26.4, 26.7, 26.8, 27.1, 25.6, 25.9
            ]
          },
          {
            name: '相对湿度',
            type: 'line',
            stack: '总量',
            yAxisIndex: 1, // 指定第二个 Y 轴
            data: [
              null, 47, 48, 58, 59, 58, 56, 57, 55, 56, 54, 55, 54, 55, 52, 53, 55, 56,
              47, 48, 45, 46, 47, 48, 55, 56, 45, 46, 48, 49, 48, 49, 58, 59, 54, 55,
              54, 55, 52, 53, 49, 50, 47, 48, 47, 48
            ]
          }
        ]
      };

      this.chart.setOption(option);
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
