<template>
  <div class="warehouse-management">
    <h1 class="title">仓库管理</h1>
    <div class="toolbar">
      <button @click="showAddModal = true">添加货品</button>
      <input v-model="searchQuery" placeholder="搜索货品" />
    </div>
    <table class="product-table">
      <thead>
      <tr>
        <th>ID</th>
        <th>名称</th>
        <th>数量</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="product in filteredProducts" :key="product.id">
        <td>{{ product.id }}</td>
        <td>{{ product.name }}</td>
        <td>{{ product.quantity }}</td>
        <td>
          <button @click="editProduct(product)">编辑</button>
          <button @click="deleteProduct(product.id)">删除</button>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="statistics">
      <p>总数量: {{ totalQuantity }}</p>
    </div>

    <h2 class="subtitle">出入库记录</h2>
    <table class="record-table">
      <thead>
      <tr>
        <th>ID</th>
        <th>名称</th>
        <th>类型</th>
        <th>数量</th>
        <th>日期</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="record in stockRecords" :key="record.id">
        <td>{{ record.id }}</td>
        <td>{{ record.name }}</td>
        <td>{{ record.type }}</td>
        <td>{{ record.quantity }}</td>
        <td>{{ record.date }}</td>
      </tr>
      </tbody>
    </table>
    <button @click="showAddRecordModal = true">添加出入库记录</button>

    <CustomModal v-if="showAddModal" @close="showAddModal = false">
      <h2 slot="header">添加货品</h2>
      <div slot="body">
        <form @submit.prevent="addProduct">
          <label>名称: <input v-model="newProduct.name" required /></label>
          <label>数量: <input v-model.number="newProduct.quantity" type="number" required /></label>
          <button type="submit">添加</button>
        </form>
      </div>
    </CustomModal>

    <CustomModal v-if="showEditModal" @close="showEditModal = false">
      <h2 slot="header">编辑货品</h2>
      <div slot="body">
        <form @submit.prevent="updateProduct">
          <label>名称: <input v-model="currentProduct.name" required /></label>
          <label>数量: <input v-model.number="currentProduct.quantity" type="number" required /></label>
          <button type="submit">更新</button>
        </form>
      </div>
    </CustomModal>

    <CustomModal v-if="showAddRecordModal" @close="showAddRecordModal = false">
      <h2 slot="header">添加出入库记录</h2>
      <div slot="body">
        <form @submit.prevent="addStockRecord">
          <label>名称: <input v-model="newRecord.name" required /></label>
          <label>类型:
            <select v-model="newRecord.type" required>
              <option value="入库">入库</option>
              <option value="出库">出库</option>
            </select>
          </label>
          <label>数量: <input v-model.number="newRecord.quantity" type="number" required /></label>
          <label>日期: <input v-model="newRecord.date" type="date" required /></label>
          <button type="submit">添加</button>
        </form>
      </div>
    </CustomModal>
    <div style="display: flex;margin-top: 2vh">
      <!-- 饼状图 - 库存情况 -->
      <div class="echarts-container">
        <div class="echarts" ref="pieChart"></div>
      </div>

      <!-- 柱状图 - 出入库记录 -->
      <div class="echarts-container">
        <div class="echarts" ref="barChart"></div>
      </div>
    </div>

  </div>
</template>

<script>
import CustomModal from './CustomModal.vue';
import * as echarts from 'echarts';

export default {
  name: 'WarehouseManagement',
  components: {
    CustomModal
  },
  data() {
    return {
      products: [
        { id: 1, name: '地西洋片', quantity: 335 },
        { id: 2, name: '艾司唑仑片', quantity: 310 },
        { id: 3, name: '氨基比林咖啡因片', quantity: 234 },
        { id: 4, name: '氨咖黄敏胶囊', quantity: 135 },
        { id: 5, name: '阿司匹林肠溶片', quantity: 1548 },
        { id: 6, name: '未使用', quantity: 200, itemStyle: {color: '#7e838d'} }
      ],
      stockRecords: [
        { id: 1, name: '地西洋片', type: '入库', quantity: 100, date: '2024-01-01' },
        { id: 2, name: '艾司唑仑片', type: '出库', quantity: 50, date: '2024-01-02' }
      ],
      showAddModal: false,
      showEditModal: false,
      showAddRecordModal: false,
      newProduct: {
        name: '',
        quantity: 0
      },
      currentProduct: null,
      newRecord: {
        name: '',
        type: '入库',
        quantity: 0,
        date: ''
      },
      searchQuery: ''
    };
  },
  computed: {
    filteredProducts() {
      return this.products.filter(product =>
          product.name.includes(this.searchQuery)
      );
    },
    totalQuantity() {
      return this.products.reduce((sum, product) => sum + product.quantity, 0);
    }
  },
  methods: {
    addProduct() {
      const newId = this.products.length ? Math.max(...this.products.map(p => p.id)) + 1 : 1;
      const newProduct = { ...this.newProduct, id: newId };
      this.products.push(newProduct);
      this.showAddModal = false;
      this.newProduct = { name: '', quantity: 0 };
    },
    editProduct(product) {
      this.currentProduct = { ...product };
      this.showEditModal = true;
    },
    updateProduct() {
      const index = this.products.findIndex(p => p.id === this.currentProduct.id);
      if (index !== -1) {
        this.products.splice(index, 1, this.currentProduct);
      }
      this.showEditModal = false;
      this.currentProduct = null;
    },
    deleteProduct(id) {
      this.products = this.products.filter(p => p.id !== id);
    },
    addStockRecord() {
      const newId = this.stockRecords.length ? Math.max(...this.stockRecords.map(r => r.id)) + 1 : 1;
      const newRecord = { ...this.newRecord, id: newId };
      this.stockRecords.push(newRecord);
      this.showAddRecordModal = false;
      this.newRecord = { name: '', type: '入库', quantity: 0, date: '' };
    },
    // 渲染饼状图
    renderPieChart() {
      const pieChart = echarts.init(this.$refs.pieChart);
      const pieOption = {
        title: {
          text: '库存情况',
          left: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 10,
          data: this.products.map(product => product.name)
        },
        series: [
          {
            name: '库存情况',
            type: 'pie',
            radius: '50%',
            center: ['50%', '60%'],
            data: this.products.map(product => ({ value: product.quantity, name: product.name })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      pieChart.setOption(pieOption);
    },
    // 渲染柱状图
    renderBarChart() {
      const barChart = echarts.init(this.$refs.barChart);
      const barOption = {
        title: {
          text: '出入库记录',
          left: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['入库', '出库'],
          top: 30,
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: {
          type: 'category',
          data: this.stockRecords.map(record => record.name),
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: '#fff'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            name: '入库',
            type: 'bar',
            data: this.stockRecords.filter(record => record.type === '入库').map(record => record.quantity)
          },
          {
            name: '出库',
            type: 'bar',
            data: this.stockRecords.filter(record => record.type === '出库').map(record => record.quantity)
          }
        ]
      };
      barChart.setOption(barOption);
    }
  },
  mounted() {
    this.renderPieChart();
    this.renderBarChart();
  }
};
</script>

<style scoped>
html, body, #app {
  height: 100%;
  margin: 0;
}

.warehouse-management {
  background-color: #12142b;
  color: white;
  padding: 20px;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.toolbar {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.product-table, .record-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.product-table th, .product-table td, .record-table th, .record-table td {
  border: 1px solid white;
  padding: 10px;
  text-align: left;
}

.product-table th, .record-table th {
  background-color: #343a40;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

form label {
  display: block;
  margin-bottom: 10px;
}

form input, form select {
  width: calc(100% - 20px);
  padding: 5px;
  margin-bottom: 20px;
}

.statistics {
  margin-top: 20px;
  text-align: right;
}

.subtitle {
  margin-top: 20px;
  margin-bottom: 10px;
}

.echarts-container {
  width: 50%;
  height: 300px;
  margin-top: 20px;
}

.echarts {
  width: 100%;
  height: 100%;
}
</style>
