<template>
  <div>
    <div class="level1">
      <div @click="toggleMainList(item)" :class="active === item ? 'btnact' : 'btn'"
           v-for="(item, index) in Object.keys(Level1List)" v-bind:key="index">
        {{ item }}
      </div>
    </div>
    <div class="do-fun">
      <div class="level2">
        <div @click="toggleL2List(index)" :class="actLevel2 == index ? 'act' : ''"
             v-for="(item, index) in Level1List[active]" v-bind:key="index">
          {{ item }}
        </div>
      </div>
      <div class="list-area">
        <div v-if="listIndex==3 && active == '行政事务' && Level1List[active][actLevel2] == '审批流程' ">
          <ApprovalProcess/>
        </div>
        <div v-else-if="listIndex==3 && active == '行政事务' && Level1List[active][actLevel2] == '我的审批' ">
          <ApprovalProcessAdmin/>
        </div>
        <div v-else-if="listIndex==3 && active == '人事管理' && Level1List[active][actLevel2] == '员工档案' ">
          <Renshi/>
        </div>
        <div v-else-if="listIndex==3 && active == '企业论坛' && Level1List[active][actLevel2] == '企业论坛' ">
          <Forum/>
        </div>
        <div v-else-if="listIndex==3 && active == '党建中心' && Level1List[active][actLevel2] == '党建活动' ">
          <DangJian/>
        </div>
        <div v-else>
          <div class="btns">
            <div class="list-title">{{ Level1List[active][actLevel2] }}</div>
            <div>
              <div class="fun-btn">新增</div>
              <div class="fun-btn">编辑</div>
              <div class="fun-btn">删除</div>
            </div>
          </div>
          <div class="nodata">
            <img src="../assets/images/bgimg/notfund.svg" alt="">
            <div style="font-size: 1.2rem">待添加数据</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ApprovalProcess from './ApprovalProcess.vue';
import ApprovalProcessAdmin from './ApprovalProcessAdmin.vue';
import Forum from './Forum.vue';
import DangJian from './DangJian.vue';
import Renshi from './xingzheng/Renshi.vue';
export default {
  components: {
    ApprovalProcess,
    ApprovalProcessAdmin,
    Forum,
    DangJian,
    Renshi
  },
  props: {
    listIndex: {
      type: Number,
      default: 1
    },
    admin: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      active: '',
      actLevel2: 0,
      Level1List: {
        "项目管理": [
          "项目计划",
          "项目进度跟踪",
          "项目文档管理",
          "项目评估与报告"
        ],
        "实验室管理": [
          "实验室资源管理",
          "试验记录",
          "样品管理",
          "仪器设备管理"
        ],
        "质量控制": [
          "质量标准",
          "质量检测",
          "不合格品管理",
          "质量报告"
        ],
        "文档管理": [
          "技术文档",
          "标准操作规程（SOPs）",
          "合规文件",
          "版本控制"
        ],
        "知识管理": [
          "技术知识库",
          "专利管理",
          "文献资料",
          "技术培训"
        ],
        "数据管理": [
          "数据采集",
          "数据分析",
          "数据报告",
          "数据安全"
        ]
      }
    };
  },
  mounted() {
    if (this.listIndex == 1) {
      this.active = "项目管理";
      if(!this.admin ){
        this.Level1List = {
          "项目管理": [
            "项目进度跟踪",
            "项目文档管理",
            "项目评估与报告"
          ],

          "质量控制": [
            "质量标准",
            "质量检测",
            "不合格品管理",
            "质量报告"
          ],
          "知识管理": [
            "技术知识库",
            "专利管理",
            "文献资料",
            "技术培训"
          ],
          "数据管理": [
            "数据采集",
            "数据分析",
            "数据报告",
            "数据安全"
          ]
        }
      }
    } else if (this.listIndex == 2) {
      this.Level1List = {
        "生产计划": [
          "生产排程",
          "物料需求计划（MRP）",
          "生产订单管理"
        ],
        "工艺管理": [
          "工艺设计",
          "工艺优化",
          "工艺验证"
        ],
        "设备管理": [
          "设备维护",
          "设备校准",
          "设备台账"
        ],
        "生产监控": [
          "生产实时监控",
          "生产异常处理",
          "生产报告"
        ]
      };
      this.active = "生产计划";
    } else if (this.listIndex == 3) {

      if (this.admin){
        this.Level1List = {
          "人事管理": [
            "员工档案",
          ],
          "企业论坛": [
            "企业论坛",
          ],
          "党建中心": [
            "党建活动",
          ]
        }
      }else{
        this.Level1List = {
          "人事管理": [
            "员工档案",
          ],
          "企业论坛": [
            "企业论坛",
          ],
          "党建中心": [
            "党建活动",
          ]
        }
      }
      this.active = "人事管理";
    } else if (this.listIndex == 4) {
      this.Level1List = {

        "供应商管理": [
          "供应商信息",
          "合作协议",
          "供应商评价"
        ],
        "采购订单": [
          "订单申请",
          "订单审批",
          "订单跟踪"
        ],
        "采购合同": [
          "合同起草",
          "合同审批",
          "合同归档"
        ]

      }

      this.active = "供应商管理";
    } else if (this.listIndex == 5) {
      this.Level1List = {

        "客户管理": [
          "客户信息",
          "客户拜访",
          "客户反馈"
        ],
        "销售订单": [
          "订单录入",
          "订单审核",
          "订单跟踪"
        ],
        "销售报价": [
          "报价制作",
          "报价审批",
          "报价跟踪"
        ],
        "销售合同": [
          "合同起草",
          "合同审批",
          "合同归档"
        ],
        "销售统计": [
          "销售额分析",
          "客户分析",
          "产品销售分析"
        ]

      }
      this.active = "客户管理";
    } else if (this.listIndex == 6) {
      this.Level1List = {

        "财务管理": [
          "预算管理",
          "会计核算",
          "报销管理",
          "税务管理",
          "资金管理"
        ],
        "固定资产": [
          "资产登记",
          "资产折旧",
          "资产清查"
        ],
        "设备管理": [
          "设备采购",
          "设备维护",
          "设备报废"
        ],
        "IT资产管理": [
          "软件资产",
          "硬件资产",
          "许可证管理"
        ],
        "车辆管理": [
          "车辆登记",
          "车辆维护",
          "车辆报废"
        ],
        "生产批号管理": [
          "生产批号登记",
          "生产批号追溯",
          "生产批号归档"
        ],
        "仓库管理": [
          "入库管理",
          "出库管理",
          "库存盘点"
        ]

      }
      this.active = "固定资产";
    } else if (this.listIndex == 7) {
      this.Level1List = {
        "市场营销": [
          "市场调研",
          "品牌推广",
          "宣传活动"
        ],
        "客户服务": [
          "客户投诉处理",
          "售后服务",
          "用户反馈管理"
        ],
        "数据分析": [
          "销售数据分析",
          "客户数据分析",
          "市场趋势分析"
        ]
      }
      this.active = "市场营销";
    } else if (this.listIndex == 8) {
      this.Level1List = {

        "新药研发": [
          "新药项目管理",
          "临床试验管理",
          "新药审批申请"
        ],
        "药品注册管理": [
          "药品注册申请",
          "注册文件管理",
          "注册审批流程"
        ],
        "药品质量控制": [
          "药品检验标准",
          "药品质量监控",
          "不合格品管理"
        ],
        "创新技术研究": [
          "技术文献检索",
          "技术创新项目",
          "技术成果转化"
        ]
      }


      this.active = "新药研发";
    }

  }
  ,
  methods: {
    toggleMainList(name) {
      this.active = name;
      this.actLevel2 = 0; // 重置二级菜单的选中状态
    }
    ,
    toggleL2List(index) {
      this.actLevel2 = index;
    }
  }
}
</script>

<style lang="scss" scoped>
/* Add any additional styles here */
.level1 {
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn, .btnact {
    cursor: pointer;
    background: url("../assets/images/bgimg/title1.png") no-repeat;
    background-size: 100% 100%;
    color: #ccc;
    padding: 0.5vw 2vw;
    font-weight: bold;
    letter-spacing: 3px;
    margin-right: 2vw;
  }

  .btnact {
    background: url("../assets/images/bgimg/title1act.png") no-repeat;
    background-size: 100% 100%;
  }

  .btn:hover {
    color: #fff;
  }
}

.do-fun {
  display: flex;
  padding: 5vh 10vw;
  color: #fff;

  .level2 {
    width: 15vw;

    > div {
      cursor: pointer;
      width: 9vw;
      margin-bottom: 2.5vh;
      padding: 1.2vh 0 1.2vh 2rem;
      background: url("../assets/images/bgimg/title2.png") no-repeat;
      background-size: 100% 100%;
      font-weight: bold;
      color: #ccc;
    }

    > div:hover {
      color: #fff;
    }

    .act {
      color: #fff;
    }
  }

  .list-area {
    flex: 1;
    background: url("../assets/images/bgimg/mainlistbg.png") no-repeat;
    background-size: 100% 100%;
    height: 60vh;
    padding: 2vw;
    overflow: hidden;
    .btns {
      display: flex;
      justify-content: space-between;

      .list-title {
        font-size: 1.5rem;
      }
    }

    .nodata {
      text-align: center;
      padding-top: 10vh;

      img {
        width: 5vw;
      }
    }

    .fun-btn {
      cursor: pointer;
      display: inline-block;
      background: url("../assets/images/bgimg/funbtn.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 1vw;
      padding: 0.2vw 1vw;
    }
  }
}
</style>
