<template>
  <div ref="chart" style="width: 100%; height: 90%;"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'FloorTreemap',
  data() {
    return {
      chart: null,
      data: this.generateRandomData()
    };
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chart);
    this.setOptions();
  },
  methods: {
    generateRandomData() {
      const rooms = [];
      const roomNames = ['生产线 A', '生产线 B', '生产线 C', '生产线 D', '生产线 E'];
      roomNames.forEach(name => {
        const size = Math.floor(Math.random() * 100 + 50);
        const temperature = Math.floor(Math.random() * 25 + 10);
        const humidity = Math.floor(Math.random() * 40 + 30);
        const staff = Math.floor(Math.random() * 10 + 1);
        rooms.push({
          name: name,
          value: size,
          temperature: temperature,
          humidity: humidity,
          staff: staff
        });
      });
      return rooms;
    },
    setOptions() {
      const option = {
        tooltip: {
          formatter: function (info) {
            const value = info.value;
            const { temperature, humidity, staff } = info.data;
            return `
              <div>
                <strong>${info.name}</strong><br/>
                大小: ${value}<br/>
                温度: ${temperature}°C<br/>
                湿度: ${humidity}%<br/>
                上岗人数: ${staff}
              </div>
            `;
          }
        },
        series: [
          {
            name: '区域监控',
            type: 'treemap',
            data: this.data.map(item => ({
              name: item.name,
              value: item.value,
              itemStyle: {
                color: this.getColor(item.temperature)
              },
              label: {
                show: true,
                formatter: [
                  '{b}',
                  `大小: {c}`,
                  `温度: ${item.temperature}°C`,
                  `上岗人数: ${item.staff}`,
                  `湿度: ${item.humidity}%`
                ].join('\n'),
                rich: {
                  temperature: {
                    color: '#ff0000'
                  },
                  staff: {
                    color: '#00ff00'
                  },
                  humidity: {
                    color: '#0000ff'
                  }
                }
              },
              temperature: item.temperature,
              humidity: item.humidity,
              staff: item.staff
            })),
            label: {
              show: true,
              formatter: function(info) {
                const { temperature, humidity, staff } = info.data;
                return `${info.name}\nSize: ${info.value}\nTemp: ${temperature}°C\nStaff: ${staff}\nHumidity: ${humidity}%`;
              },
              position: 'insideTopLeft'
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1,
              gapWidth: 2
            },
            levels: [
              {
                itemStyle: {
                  borderColor: '#555',
                  borderWidth: 4,
                  gapWidth: 4
                }
              }
            ]
          }
        ]
      };
      this.chart.setOption(option);
    },
    getColor(temperature) {
      const minTemp = 10;
      const maxTemp = 35;
      const minColor = [0, 170, 255]; // 浅蓝
      const maxColor = [255, 136, 0]; // 橘红

      const ratio = (temperature - minTemp) / (maxTemp - minTemp);
      const r = Math.round(minColor[0] + ratio * (maxColor[0] - minColor[0]));
      const g = Math.round(minColor[1] + ratio * (maxColor[1] - minColor[1]));
      const b = Math.round(minColor[2] + ratio * (maxColor[2] - minColor[2]));

      return `rgb(${r},${g},${b})`;
    }
  }
};
</script>

<style scoped>
</style>
