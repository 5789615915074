<template>
  <div class="approval-process">
    <div class="btns">
      <div class="list-title">文档管理</div>
      <div>
        <el-dialog title="新增" :visible.sync="applymodel" width="30%">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="标题">
              <el-input v-model="form.data1"></el-input>
            </el-form-item>
            <el-form-item label="作者">
              <el-input v-model="form.data2"></el-input>
            </el-form-item>
            <el-form-item label="期限">
              <el-input v-model="form.data3"></el-input>
            </el-form-item>
            <el-form-item label="有效起止时间">
              <el-input v-model="form.data4"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
              <el-button @click="applymodel=false">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>

    </div>
    <div class="list">
      <div class="item head">
        <div>标题</div>
        <div>作者</div>
        <div>发布日期</div>
      </div>
      <div @click="kantie(item)" class="item" v-for="(item,index) in list" :key="index">
        <div>{{ item.title }}</div>
        <div>{{ item.user }}</div>
        <div>{{ timestampToDate(item.date1) }}</div>
      </div>
    </div>
    <el-dialog :title="Tie.title" :visible.sync="showTie" width="30%">
      <div>{{Tie.user}}{{timestampToDate(Tie.date1) }}</div>
      <div v-html="Tie.content" class="tiezi"></div>

    </el-dialog>
  </div>
</template>

<script>
import zh from '@/quill-zh';  // 引入中文语言包
export default {
  name: 'ApprovalProcess',
  components: {
  },
  data() {
    return {
      showTie:false,
      Tie:{},
      editorOptions: {
        theme: 'snow',
        placeholder: zh.placeholder,
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image']
          ]
        },
        formats: zh.formats  // 设置格式的中文名称
      },
      applymodel: false,
      form: {
        title:'',
        user:'',
        date1:'',
        content:'',
      },
      list: [
        {
          title:'实验室温湿度要求',
          user:'管理员',
          date1:'1723445433000',
          content:'4  规程：\n' +
              '4.1 定义\n' +
              '4.1.1 温度：即空气温度是指空气的冷热程度。多用摄氏度℃表示，在度数前加“-”，表示零下多少摄氏度。\n' +
              '4.1.2 相对湿度：是指空气中实际含有水蒸气量（绝对湿度）距离饱和状态（饱和湿度）程度的百分比。即在一定温度下，绝对湿度占饱和湿度的百分比数。相对湿度用百分率来表示，公式为：相对湿度=绝对湿度/饱和湿度×100%。\n' +
              '4.2 各区域温度、湿度要求\n' +
              '4.2.1 质量部仪器一室、理化室、仪器三室、气相室、液相室、仪器四室（液相室）、试剂库、仪器五室：温度控制在10℃～30℃，相对湿度≤70%。\n' +
              '4.2.2 质量部称量室、仪器二室：温度控制在10℃～30℃，相对湿度≤65%。\n' +
              '4.2.3 留样室：温度控制在25℃以下，相对湿度≤65%。\n' +
              '4.2.4 冰箱冷藏区温度控制在5℃±3℃、冷冻区温度控制在-20±3℃。\n' +
              '4.2.5 技术部原辅料库温度控制在20℃以下，其余实验室仅对温湿度进行记录，无特殊要求。\n' +
              '4.2.6 分子工程部试剂库要求常温通风，其余实验室仅对温湿度进行记录，无特殊要求。\n' +
              '4.2.7 其它有特殊要求的试验，可开启相应的控制设施以达到要求，并在相应的方案、记录中进行描述或记录。\n' +
              '4.3 温度、湿度管理 \n' +
              '4.3.1 对具有温湿度要求的实验室配备相应的控制设施，并在实验室及主要冰箱中的合适位置放置温湿度监测设备，确保温湿度满足相应试验及储存的要求。\n' +
              '4.3.2 建立《温湿度观察记录》，相关人员在工作日每天两次记录各实验室、冰箱的温湿度，上午09：30-10：30一次，下午14：30-15：30一次。试验开始时也需在实验记录本上记录试验环境温湿度，便于追踪检查。 \n' +
              '4.3.3 冬季室温低于15℃、夏天室温超过30℃时，如有实验需要，可开动空调制热、制冷。\n' +
              '4.3.4 冰箱冷冻室准备充足的冰或冰袋，预防停电或冰箱发生故障时冷藏样品的保温，若故障或断电时间较长，须转移到公司其它冰箱内。\n' +
              '4.4 校准：\n' +
              '温湿度计每年进行一次第三方校准，经校准合格后的温湿度计方可使用。\n' +
              '4.5 注意事项：\n' +
              '4.5.1 温湿度计应挂置在通风良好处，不得装在阳光直射的墙面和空调机出气口，且30min后可指示正确值。\n' +
              '4.5.2 实验室温湿度监测值为静态指示值。读数采用四舍五入方法，读到最小分度值。\n',
        },
          
      ],
    };
  },
  methods: {
    kantie(arr){
      this.Tie = arr;
      this.showTie = true
    },
    submit() {
      this.list.unshift(this.form)
      this.applymodel = false
      this.form = {
        data1:'',
        data2:'',
        data3:'',
        data4:'',
      }
    },
    timestampToDate(timestamp) {
      timestamp = Number(timestamp);
      var date = new Date(timestamp); // 时间戳转换成Date对象
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，需要+1因为月份从0开始
      var day = date.getDate(); // 获取日
      var hours = date.getHours(); // 获取小时
      var minutes = date.getMinutes(); // 获取分钟
      var seconds = date.getSeconds(); // 获取秒钟

      // 格式化月份、日期、小时、分钟和秒
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 组合成日期时间字符串
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
  },
}
</script>

<style scoped>
/deep/ .el-dialog {
  background: #1e1e2e !important;
  color: #fff !important;
}

/deep/ .el-dialog__title {
  color: #fff !important;
}
.tiezi{
  margin-top: 2vh;
  color: #fff ;
  padding: 1vw;
  width: 80%;
}
.tiezi img{
  width: 80% !important;
}
.list {
  height: 55vh;
  overflow-y: auto;
  .head {
    font-weight: bold;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0c929f;
    text-align: center;
    cursor: pointer;

    > div {
      flex: 1;
      text-align: center;
      padding: 2rem;
    }
  }
  .item:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.btns {
  display: flex;
  justify-content: space-between;

  .list-title {
    font-size: 1.5rem;
  }
}

.nodata {
  text-align: center;
  padding-top: 10vh;

  img {
    width: 5vw;
  }
}

.fun-btn {
  cursor: pointer;
  display: inline-block;
  background: url("../../assets/images/bgimg/funbtn.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 1vw;
  padding: 0.2vw 1vw;
}
</style>
