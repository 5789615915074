<template>
  <div class="approval-process">
    <div class="btns">
      <div class="list-title">稳定性试验箱数据</div>
    </div>
    <div class="list">
      <a :href="'/files/shiyan/'+item+'.xlsx'" target="_blank" class="item" v-for="(item,index) in list" :key="index">
        <img src="../../assets/images/excel.png"/>
        <div>{{item.substring(0, 8) + "..."}}</div>
      </a>
    </div>
    <el-dialog :title="Tie.title" :visible.sync="showTie" width="30%">
      <div>{{Tie.user}}{{timestampToDate(Tie.date1) }}</div>
      <div v-html="Tie.content" class="tiezi"></div>

    </el-dialog>
  </div>
</template>

<script>
import zh from '@/quill-zh';  // 引入中文语言包
export default {
  name: 'ApprovalProcess',
  components: {
  },
  data() {
    return {
      showTie:false,
      Tie:{},
      editorOptions: {
        theme: 'snow',
        placeholder: zh.placeholder,
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image']
          ]
        },
        formats: zh.formats  // 设置格式的中文名称
      },

      applymodel: false,
      form: {
        data1:'',
        data2:'',
        data3:'',
        data4:'',
      },
      list: [
          "YQ-3-063",
          "YQ-3-064",
          "YQ-3-065",
          "YQ-3-068",
          "YQ-3-069",
          "YQ-3-071",
          "YQ-3-072",
          "YQ-3-075"

      ],
    };
  },
  methods: {
    kantie(arr){
      this.Tie = arr;
      this.showTie = true
    },
    submit() {
      this.list.unshift(this.form)
      this.applymodel = false
      this.form = {
        data1:'',
        data2:'',
        data3:'',
        data4:'',
      }
    },
    timestampToDate(timestamp) {
      timestamp = Number(timestamp);
      var date = new Date(timestamp); // 时间戳转换成Date对象
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，需要+1因为月份从0开始
      var day = date.getDate(); // 获取日
      var hours = date.getHours(); // 获取小时
      var minutes = date.getMinutes(); // 获取分钟
      var seconds = date.getSeconds(); // 获取秒钟

      // 格式化月份、日期、小时、分钟和秒
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 组合成日期时间字符串
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
  },
}
</script>

<style scoped>
/deep/ .el-dialog {
  background: #1e1e2e !important;
  color: #fff !important;
}

/deep/ .el-dialog__title {
  color: #fff !important;
}
.tiezi{
  margin-top: 2vh;
  color: #fff ;
  padding: 1vw;
  width: 80%;
}
.tiezi img{
  width: 80% !important;
}
.list {
  height: 55vh;
  overflow-y: auto;
  .head {
    font-weight: bold;
  }
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 9vh;
    height: 11vh;
    padding: 1vh;
    margin: 0 2vh;
      img{
        width: 9vh;
      }
    div{
      overflow: hidden;
    }
  }
  .item:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.btns {
  display: flex;
  justify-content: space-between;

  .list-title {
    font-size: 1.5rem;
  }
}

.nodata {
  text-align: center;
  padding-top: 10vh;

  img {
    width: 5vw;
  }
}

.fun-btn {
  cursor: pointer;
  display: inline-block;
  background: url("../../assets/images/bgimg/funbtn.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 1vw;
  padding: 0.2vw 1vw;
}
</style>
