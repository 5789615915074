<template>
  <div class="approval-process">
    <div class="btns">
      <div class="list-title">审批流程</div>
      <div>
        <div class="fun-btn" @click="applymodel=true">提交审批</div>
        <el-dialog title="提交审批" :visible.sync="applymodel" width="30%">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="事务类型">
              <el-select v-model="form.type" placeholder="请选择">
                <el-option label="出差" value="出差"></el-option>
                <el-option label="报销" value="报销"></el-option>
                <el-option label="采购" value="采购"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="事务名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="事务时间">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" value-format="timestamp"
                                style="width: 100%;"></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-time-picker placeholder="选择时间" v-model="form.date2" value-format="timestamp"
                                style="width: 100%;"></el-time-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="上传附件">
              <el-popover
                  placement="right"
                  width="200"
                  trigger="hover"
                  content="演示版不支持上传文件">
                <el-button slot="reference" hover type="primary" size="small" disabled>选择文件</el-button>
              </el-popover>

            </el-form-item>

            <el-form-item label="备 注">
              <el-input type="textarea" v-model="form.mark"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
              <el-button @click="applymodel=false">取消</el-button>
            </el-form-item>
          </el-form>

        </el-dialog>
      </div>

    </div>
    <div class="list">
      <div class="item head">
        <div>类型</div>
        <div>名称</div>
        <div>备注</div>
        <div>审批人</div>
        <div>附件</div>
        <div>状态</div>
        <div>日期</div>
      </div>
      <div class="item" v-for="(item,index) in list" :key="index">
        <div>{{ item.type }}</div>
        <div>{{ item.name }}</div>
        <div>{{ item.mark }}</div>
        <div>{{ item.spr }}</div>
        <div>
          <div v-if="item.fj=='无'"> {{ item.fj }}</div>
          <div v-else>
            <el-popover
                placement="right"
                width="400"
                trigger="click">
              <img src="../assets/images/fp.png" alt="" style="width: 100%">
              <el-button slot="reference">{{ item.fj }}</el-button>
            </el-popover>
            
          </div>

        </div>
        <div>
          <div v-if="item.state=='已驳回'">
            <el-popover
                placement="right"
                title="驳回原因"
                width="200"
                trigger="hover"
                :content="item.res">

              <el-link  slot="reference" type="danger">{{ item.state }}</el-link>
            </el-popover>
          </div>
          <div v-else>{{ item.state }}</div>


        </div>
        <div>{{ timestampToDate(item.date1) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApprovalProcess',
  data() {
    return {
      applymodel: false,
      form: {
        type: '',
        name: '',
        date1: '',
        date2: '',
        spr:'张三',
        mark: '',
        res: '',
        fj:'无',
        state: '审批中',
      },
      list: [
        {
          type: '采购',
          name: '阿司匹林原料采购',
          date1: '1718728779',
          date2: '1718728779',
          spr:'王五',
          res:'',
          fj:'无',
          mark: '现原料不足生产需求，拟采购1千克，采购金额1000元，大写金额壹仟元整。',
          state: '审批中',
        },
        {
          type: '报销',
          name: '出差报销',
          date1: '1718728779',
          date2: '1718728779',
          spr:'张三',
          res:'发票金额与报销金额不符，不予通过',
          fj:'发票0601.png',
          mark: '到访历城区客户出差报销100元，大写金额壹佰元整',
          state: '已驳回',
        },
        {
          type: '出差',
          name: '到访历城区客户出差',
          date1: '1718728779',
          date2: '1718728779',
          spr:'李四',
          res:'',
          fj:'无',
          mark: '进行采购沟通',
          state: '已通过',
        },

      ],
    };
  },
  methods: {
    submit() {
      this.list.unshift(this.form)
      this.applymodel = false
      this.form = {
        type: '',
        name: '',
        date1: '',
        date2: '',
        spr:'张三',
        mark: '',
        res: '',
        fj:'无',
        state: '审批中',
      }
    },
    timestampToDate(timestamp) {
      timestamp = Number(timestamp);
      var date = new Date(timestamp); // 时间戳转换成Date对象
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，需要+1因为月份从0开始
      var day = date.getDate(); // 获取日
      var hours = date.getHours(); // 获取小时
      var minutes = date.getMinutes(); // 获取分钟
      var seconds = date.getSeconds(); // 获取秒钟

      // 格式化月份、日期、小时、分钟和秒
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 组合成日期时间字符串
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
  },
}
</script>

<style scoped>
/deep/ .el-dialog {
  background: #1e1e2e !important;
  color: #fff !important;
}

/deep/ .el-dialog__title {
  color: #fff !important;
}

.list {
  .head {
    font-weight: bold;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0c929f;
    text-align: center;
    > div {
      flex: 1;
      text-align: center;
      padding: 2rem;
    }
  }
}

.btns {
  display: flex;
  justify-content: space-between;

  .list-title {
    font-size: 1.5rem;
  }
}

.nodata {
  text-align: center;
  padding-top: 10vh;

  img {
    width: 5vw;
  }
}

.fun-btn {
  cursor: pointer;
  display: inline-block;
  background: url("../assets/images/bgimg/funbtn.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 1vw;
  padding: 0.2vw 1vw;
}
</style>
