<template>
  <div ref="chart" style="width: 100%; height:100%"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'TemperatureBarChart',
  props: {
    data: {
      type: Array,
      required: true,
      // 例子数据格式: [{ name: '地点1', baseTemp: 20, currentTemp: 18 }, { name: '地点2', baseTemp: 25, currentTemp: 30 }]
    }
  },
  data() {
    return {
      fluctuatingData: []
    };
  },
  mounted() {
    this.initializeData();
    this.renderChart();
    this.startFluctuation();
  },
  beforeDestroy() {
    this.stopFluctuation();
  },
  watch: {
    data() {
      this.initializeData();
      this.renderChart();
    }
  },
  methods: {
    initializeData() {
      // 初始化浮动数据
      this.fluctuatingData = this.data.map(item => ({
        ...item,
        originalTemp: item.currentTemp // 保存原始温度值
      }));
    },
    startFluctuation() {
      this.fluctuationInterval = setInterval(() => {
        this.fluctuateData();
        this.renderChart();
      }, 1000);
    },
    stopFluctuation() {
      clearInterval(this.fluctuationInterval);
    },
    fluctuateData() {
      this.fluctuatingData = this.fluctuatingData.map(item => {
        const fluctuation = Math.random() * 4 - 2; // 生成-2到2之间的随机数
        let newTemp = item.currentTemp + fluctuation;

        // 限制浮动范围在原有温度的上下 1-2
        const minTemp = item.originalTemp - 2;
        const maxTemp = item.originalTemp + 2;

        if (newTemp < minTemp) newTemp = minTemp;
        if (newTemp > maxTemp) newTemp = maxTemp;

        return {
          ...item,
          currentTemp: newTemp
        };
      });
    },
    renderChart() {
      const chart = echarts.init(this.$refs.chart);

      const categories = this.fluctuatingData.map(item => item.name);
      const baseTemps = this.fluctuatingData.map(item => item.baseTemp);
      const currentTemps = this.fluctuatingData.map(item => item.currentTemp);

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['基准温度', '当前温度']
        },
        xAxis: {
          type: 'category',
          data: categories
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '基准温度',
            type: 'bar',
            data: baseTemps,
            itemStyle: {
              color: '#3db6fc'
            },
            barWidth: '20%', // 调整柱子的宽度
            barGap: '30%' // 调整柱子之间的间距
          },
          {
            name: '当前温度',
            type: 'bar',
            data: currentTemps,
            itemStyle: {
              color: params => {
                const baseTemp = baseTemps[params.dataIndex];
                const currentTemp = params.data;
                return currentTemp < baseTemp ? '#27e9cb' : '#fc5659';
              }
            },
            barWidth: '20%', // 调整柱子的宽度
            barGap: '30%' // 调整柱子之间的间距
          }
        ]
      };

      chart.setOption(option);
    }
  }
};
</script>

<style scoped>
/* 你可以在这里添加自定义样式 */
</style>
