<template>
  <div class="approval-process">
    <div class="btns">
      <div class="list-title">项目计划</div>
      <div>
        <div class="fun-btn" @click="applymodel=true">新增</div>
        <el-dialog title="新增" :visible.sync="applymodel" width="30%">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="项目名称">
              <el-input v-model="form.data1"></el-input>
            </el-form-item>
            <el-form-item label="委托研发单位">
              <el-input v-model="form.data2"></el-input>
            </el-form-item>
            <el-form-item label="期限">
              <el-input v-model="form.data3"></el-input>
            </el-form-item>
            <el-form-item label="有效起止时间">
              <el-input v-model="form.data4"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
              <el-button @click="applymodel=false">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>

    </div>
    <div class="list">
      <div class="item head">
        <div>项目名称</div>
        <div>委托研发单位</div>
        <div>期限</div>
        <div>有效起止时间</div>
      </div>
      <div  class="item" v-for="(item,index) in list" :key="index">
        <div>{{ item.data1 }}</div>
        <div>{{ item.data2 }}</div>
        <div>{{ item.data3 }}</div>
        <div>{{ item.data4 }}</div>
      </div>
    </div>

    <el-dialog :title="Tie.title" :visible.sync="showTie" width="30%">
      <div>{{Tie.user}}{{timestampToDate(Tie.date1) }}</div>
      <div v-html="Tie.content" class="tiezi"></div>
    </el-dialog>
  </div>
</template>

<script>
import zh from '@/quill-zh';  // 引入中文语言包
export default {
  name: 'ApprovalProcess',
  components: {
  },
  data() {
    return {
      showTie:false,
      Tie:{},
      editorOptions: {
        theme: 'snow',
        placeholder: zh.placeholder,
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image']
          ]
        },
        formats: zh.formats  // 设置格式的中文名称
      },
      applymodel: false,
      form: {
        data1:'',
        data2:'',
        data3:'',
        data4:'',
      },
      list: [
        {
          data1:'盐酸普萘洛尔滴剂（5ml：100mg，10ml：200mg）',
          data2:'西安布瑞斯康实业有限公司',
          data3:'5年',
          data4:'2023.10.22-2028.10.21',
        },
        {
          data1:'盐酸普萘洛尔滴剂（5ml：100mg，10ml：200mg）',
          data2:'山东英祁史克生物技术有限公司',
          data3:'5年',
          data4:'2023.10.22-2028.10.21',
        },
        {
          data1:'艾托格列净二甲双胍片（2.5mg/500mg）',
          data2:'重庆科瑞制药（集团）有限公司',
          data3:'5年',
          data4:'2024.04.25-2029.04.24',
        },
        {
          data1:'硫酸奈替米星注射液一致性评价',
          data2:'深圳大佛药业股份有限公司',
          data3:'10年',
          data4:'2024.06.06--2034.06.05',
        },
        {
          data1:'伊班膦酸钠注射液与COP预灌封注射器组合件相容性',
          data2:'山东永聚医药科技股份有限公司',
          data3:'3年',
          data4:'2024.06.25-2027.06.25',
        },
        {
          data1:'艾托格列净片（5mg）',
          data2:'山东永聚医药科技股份有限公司',
          data3:'5年',
          data4:'2024.07.15-2029.07.14',
        },
        {
          data1:'托吡司特原料药',
          data2:'珠海蔚蓝医药有限公司',
          data3:'5年',
          data4:'2022.5.29-2027.5.28',
        },
        {
          data1:'盐酸普萘洛尔滴剂（5ml：100mg，10ml：200mg）',
          data2:'海南诺恩生物科技有限公司',
          data3:'5年',
          data4:'2023.12.8-2028.12.7',
        },
        {
          data1:'盐酸普萘洛尔片（10mg.20mg）',
          data2:'海南诺恩生物科技有限公司',
          data3:'5年',
          data4:'2023.12.8-2028.12.7',
        },
      ],
    };
  },
  methods: {
    kantie(arr){
      this.Tie = arr;
      this.showTie = true
    },
    submit() {
      this.list.unshift(this.form)
      this.applymodel = false
      this.form = {
        data1:'',
        data2:'',
        data3:'',
        data4:'',
      }
    },
    timestampToDate(timestamp) {
      timestamp = Number(timestamp);
      var date = new Date(timestamp); // 时间戳转换成Date对象
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，需要+1因为月份从0开始
      var day = date.getDate(); // 获取日
      var hours = date.getHours(); // 获取小时
      var minutes = date.getMinutes(); // 获取分钟
      var seconds = date.getSeconds(); // 获取秒钟

      // 格式化月份、日期、小时、分钟和秒
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 组合成日期时间字符串
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
  },
}
</script>

<style scoped>
/deep/ .el-dialog {
  background: #1e1e2e !important;
  color: #fff !important;
}

/deep/ .el-dialog__title {
  color: #fff !important;
}
.tiezi{
  margin-top: 2vh;
  color: #fff ;
  padding: 1vw;
  width: 80%;
}
.tiezi img{
  width: 80% !important;
}
.list {
  height: 55vh;
  overflow-y: auto;
  .head {
    font-weight: bold;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0c929f;
    text-align: center;
    cursor: pointer;

    > div {
      flex: 1;
      text-align: center;
      padding: 2rem;
    }
  }
  .item:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.btns {
  display: flex;
  justify-content: space-between;

  .list-title {
    font-size: 1.5rem;
  }
}

.nodata {
  text-align: center;
  padding-top: 10vh;

  img {
    width: 5vw;
  }
}

.fun-btn {
  cursor: pointer;
  display: inline-block;
  background: url("../../assets/images/bgimg/funbtn.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 1vw;
  padding: 0.2vw 1vw;
}
</style>
