<template>
  <div class="progress-bar">
    <div class="progress-bar-count">
      <span ref="count">0</span>%
    </div>
    <div class="progress-bar-title">
      今日到岗
    </div>
    <div class="progress-bar-bar">
      <div class="progress-bar-bar-inner" :style="{ width: progressWidth }"></div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  props: {
    title: {
      type: String,
      default: 'Progress Title'
    },
    current: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 100
    }
  },
  computed: {
    progressWidth() {
      return `${(this.current / this.total) * 100}%`;
    }
  },
  watch: {
    current(newVal) {  // 移除了 oldVal 参数
      this.animateCount(0, newVal);
    }
  },
  mounted() {
    this.animateCount(0, this.current);
  },
  methods: {
    animateCount(start, end) {
      anime({
        targets: this.$refs.count,
        innerHTML: [start, end],
        round: 1,
        easing: 'easeInOutQuad',
        duration: 3000
      });
    }
  }
};
</script>

<style scoped>
.progress-bar {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  >div{
    width: 100%;
  }
  box-sizing: border-box;
  text-align: center;
}

.progress-bar-title {
  font-size: 0.5vw;
  margin-bottom: 10px;
  color: #fff;
}

.progress-bar-count {
  font-size: 2.3vw;
  color: #58dbff;
  font-weight: bold;
}

.progress-bar-bar {
  width: 100%;
  height: 20px;
  background-color: #eeeeee38;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-bar-inner {
  height: 100%;
  background-color: #13adfb;
  border-radius: 10px 0 0 10px;
}
</style>
