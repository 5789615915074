<template>
  <div class="approval-process">
    <div class="btns">
      <div class="list-title">人事管理</div>

    </div>
    <div class="list">
      <div class="item head">
        <div>姓名</div>
        <div>性别</div>
        <div>部门</div>
        <div>学历</div>
        <div>岗位</div>
        <div>专业</div>
      </div>
      <div  class="item" v-for="(item,index) in list" :key="index">
        <div>{{ item.姓名 }}</div>
        <div>{{ item.性别 }}</div>
        <div>{{ item.部门 }}</div>
        <div>{{ item.学历 }}</div>
        <div>{{ item.岗位 }}</div>
        <div>{{ item.专业 }}</div>
      </div>
    </div>

    <el-dialog :title="Tie.title" :visible.sync="showTie" width="30%">
      <div>{{Tie.user}}{{timestampToDate(Tie.date1) }}</div>
      <div v-html="Tie.content" class="tiezi"></div>
    </el-dialog>
  </div>
</template>

<script>
import zh from '@/quill-zh';  // 引入中文语言包
export default {
  name: 'ApprovalProcess',
  components: {
  },
  data() {
    return {
      showTie:false,
      Tie:{},
      editorOptions: {
        theme: 'snow',
        placeholder: zh.placeholder,
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image']
          ]
        },
        formats: zh.formats  // 设置格式的中文名称
      },
      applymodel: false,
      form: {
        data1:'',
        data2:'',
        data3:'',
        data4:'',
      },
      list: [
        {
          "序号": 1,
          "姓名": "赵  云",
          "性别": "男",
          "部门": "办公室",
          "学历": "本科",
          "岗位": "副总经理",
          "专业": "生物技术"
        },
        {
          "序号": 2,
          "姓名": "赵佳钰",
          "性别": "男",
          "部门": "综合",
          "学历": "本科",
          "岗位": "项目负责人",
          "专业": "生物科学"
        },
        {
          "序号": 3,
          "姓名": "张春玉",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "副主任",
          "专业": "材料化学"
        },
        {
          "序号": 4,
          "姓名": "邵  华",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "主任",
          "专业": "制药工程"
        },
        {
          "序号": 5,
          "姓名": "王  奇",
          "性别": "男",
          "部门": "制剂室",
          "学历": "专科",
          "岗位": "副主任",
          "专业": "化学制药技术"
        },
        {
          "序号": 6,
          "姓名": "李怀伟",
          "性别": "男",
          "部门": "合成室",
          "学历": "本科",
          "岗位": "高级实验员",
          "专业": "药学"
        },
        {
          "序号": 7,
          "姓名": "沈洪海",
          "性别": "男",
          "部门": "合成室",
          "学历": "本科",
          "岗位": "部长",
          "专业": "应用化学"
        },
        {
          "序号": 8,
          "姓名": "杨耀宗",
          "性别": "男",
          "部门": "综合",
          "学历": "硕士",
          "岗位": "项目负责人",
          "专业": "有机化学"
        },
        {
          "序号": 9,
          "姓名": "岳  晖",
          "性别": "女",
          "部门": "综合",
          "学历": "硕士",
          "岗位": "项目负责人",
          "专业": "生物化学与分子生物学"
        },
        {
          "序号": 10,
          "姓名": "肖学海",
          "性别": "男",
          "部门": "合成室",
          "学历": "本科",
          "岗位": "项目负责人",
          "专业": "化学工程与工艺"
        },
        {
          "序号": 11,
          "姓名": "尹义芹",
          "性别": "女",
          "部门": "综合",
          "学历": "硕士",
          "岗位": "调研员",
          "专业": "农药学"
        },
        {
          "序号": 12,
          "姓名": "丁培辉",
          "性别": "男",
          "部门": "制剂室",
          "学历": "本科",
          "岗位": "高级实验员",
          "专业": "药学"
        },
        {
          "序号": 13,
          "姓名": "赵  峰",
          "性别": "男",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "高级实验员",
          "专业": "药学"
        },
        {
          "序号": 14,
          "姓名": "王晓苓",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "实验员",
          "专业": "化工"
        },
        {
          "序号": 15,
          "姓名": "郎  斌",
          "性别": "男",
          "部门": "综合",
          "学历": "大专",
          "岗位": "高级实验员",
          "专业": "药学"
        },
        {
          "序号": 16,
          "姓名": "李  晶",
          "性别": "女",
          "部门": "综合",
          "学历": "本科",
          "岗位": "调研员",
          "专业": "制药工程"
        },
        {
          "序号": 17,
          "姓名": "孙景瑞",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "制药工程"
        },
        {
          "序号": 18,
          "姓名": "张倩倩",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "项目负责人",
          "专业": "生物技术"
        },
        {
          "序号": 19,
          "姓名": "王洪岩",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "实验员",
          "专业": "药品生产技术"
        },
        {
          "序号": 20,
          "姓名": "李  娜",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "化学"
        },
        {
          "序号": 21,
          "姓名": "赵凌云",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "实验员",
          "专业": "药品食品检验"
        },
        {
          "序号": 22,
          "姓名": "郑  言",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "实验员",
          "专业": "质量与安全"
        },
        {
          "序号": 23,
          "姓名": "张金莲",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "药物制剂"
        },
        {
          "序号": 24,
          "姓名": "姚景云",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "项目负责人",
          "专业": "药物分析"
        },
        {
          "序号": 25,
          "姓名": "杜明明",
          "性别": "女",
          "部门": "制剂室",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "药学"
        },
        {
          "序号": 26,
          "姓名": "部袭凤",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "实验员",
          "专业": "药学"
        },
        {
          "序号": 27,
          "姓名": "靳肖敏",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "QA",
          "专业": "制药工程"
        },
        {
          "序号": 28,
          "姓名": "赵宣宣",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "化学工程与工艺"
        },
        {
          "序号": 29,
          "姓名": "王俏",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "实验员",
          "专业": "药品生产技术"
        },
        {
          "序号": 30,
          "姓名": "王守华",
          "性别": "女",
          "部门": "生产部",
          "学历": "本科",
          "岗位": "生产负责人",
          "专业": "药学"
        },
        {
          "序号": 31,
          "姓名": "李玉梅",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "质量负责人",
          "专业": "药学"
        },
        {
          "序号": 32,
          "姓名": "项欣荣",
          "性别": "男",
          "部门": "制剂室",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "药物制剂"
        },
        {
          "序号": 33,
          "姓名": "李海燕",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "制药工程"
        },
        {
          "序号": 34,
          "姓名": "杨航",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "实验员",
          "专业": "药品生产技术"
        },
        {
          "序号": 35,
          "姓名": "陈秀秀",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "实验员",
          "专业": "药学"
        },
        {
          "序号": 36,
          "姓名": "王玉",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "实验员",
          "专业": "药学"
        },
        {
          "序号": 37,
          "姓名": "靳婷婷",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "分析负责人",
          "专业": "制药工程"
        },
        {
          "序号": 38,
          "姓名": "张有华",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "分析负责人",
          "专业": "制药工程"
        },
        {
          "序号": 39,
          "姓名": "尹延霞",
          "性别": "女",
          "部门": "质量部",
          "学历": "硕士",
          "岗位": "分析负责人",
          "专业": "微生物与生化药学"
        },
        {
          "序号": 40,
          "姓名": "乍思爽",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "实习生",
          "专业": "市场营销"
        },
        {
          "序号": 41,
          "姓名": "夏德香",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "制药工程"
        },
        {
          "序号": 42,
          "姓名": "贾玉珠",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "食品科学工程"
        },
        {
          "序号": 43,
          "姓名": "刘晓楠",
          "性别": "女",
          "部门": "制剂室",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "药学"
        },
        {
          "序号": 44,
          "姓名": "黄鑫鑫",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "qc",
          "专业": "药学"
        },
        {
          "序号": 45,
          "姓名": "何芊",
          "性别": "女",
          "部门": "质量部",
          "学历": "大专",
          "岗位": "QA",
          "专业": "药学"
        },
        {
          "序号": 46,
          "姓名": "张亚红",
          "性别": "女",
          "部门": "质量部",
          "学历": "本科",
          "岗位": "实验员",
          "专业": "化学工程与工艺"
        }
      ],
    };
  },
  methods: {
    kantie(arr){
      this.Tie = arr;
      this.showTie = true
    },
    submit() {
      this.list.unshift(this.form)
      this.applymodel = false
      this.form = {
        data1:'',
        data2:'',
        data3:'',
        data4:'',
      }
    },
    timestampToDate(timestamp) {
      timestamp = Number(timestamp);
      var date = new Date(timestamp); // 时间戳转换成Date对象
      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份，需要+1因为月份从0开始
      var day = date.getDate(); // 获取日
      var hours = date.getHours(); // 获取小时
      var minutes = date.getMinutes(); // 获取分钟
      var seconds = date.getSeconds(); // 获取秒钟

      // 格式化月份、日期、小时、分钟和秒
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // 组合成日期时间字符串
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
  },
}
</script>

<style scoped>
/deep/ .el-dialog {
  background: #1e1e2e !important;
  color: #fff !important;
}

/deep/ .el-dialog__title {
  color: #fff !important;
}
.tiezi{
  margin-top: 2vh;
  color: #fff ;
  padding: 1vw;
  width: 80%;
}
.tiezi img{
  width: 80% !important;
}
.list {
  height: 55vh;
  overflow-y: auto;
  .head {
    font-weight: bold;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0c929f;
    text-align: center;
    cursor: pointer;

    > div {
      flex: 1;
      text-align: center;
      padding: 2rem;
    }
  }
  .item:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.btns {
  display: flex;
  justify-content: space-between;

  .list-title {
    font-size: 1.5rem;
  }
}

.nodata {
  text-align: center;
  padding-top: 10vh;

  img {
    width: 5vw;
  }
}

.fun-btn {
  cursor: pointer;
  display: inline-block;
  background: url("../../assets/images/bgimg/funbtn.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 1vw;
  padding: 0.2vw 1vw;
}
</style>
