<template>
  <div class="progress-ring">
    <div ref="ringChart" class="ring-chart"></div>
    <div class="outer-circle"></div>
    <div class="progress-text" :style="{ color: progressColor }">{{ currentProgress }}%</div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    totalProgress: {
      type: Number,
      required: true
    },
    currentProgress: {
      type: Number,
      required: true
    },
    progressColor: {
      type: String,
      default: 'red'
    },
    outerCircleColor: {
      type: String,
      default: '#eee'
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.ringChart);
      const option = {
        series: [
          {
            type: 'pie',
            radius: ['70%', '85%'],
            hoverAnimation: false,
            startAngle: 225,
            minAngle: 0,
            data: [
              {
                value: this.currentProgress,
                itemStyle: {
                  color: this.progressColor
                }
              },
              {
                value: this.totalProgress - this.currentProgress,
                itemStyle: {
                  color: '#eeeeee38'
                }
              }
            ],
            label: {
              show: false
            },
            silent: true
          }
        ]
      };
      this.chart.setOption(option);
    }
  },
  watch: {
    currentProgress() {
      this.chart.setOption({
        series: [
          {
            data: [
              {
                value: this.currentProgress,
                itemStyle: {
                  color: this.progressColor
                }
              },
              {
                value: this.totalProgress - this.currentProgress,
                itemStyle: {
                  color: '#eee'
                }
              }
            ]
          }
        ]
      });
    }
  }
};
</script>

<style scoped>
.progress-ring {
  position: relative;
  width: 100%;
  height: 100%;
}

.ring-chart {
  position: absolute;
  width: 100%;
  height: 100%;
}

.outer-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #eee;
  border-radius: 50%;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5vw;
  font-weight: bold;
}
</style>
