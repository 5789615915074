<template>
  <div>
    <div class="level1">
      <div @click="toggleMainList(item)" :class="active === item ? 'btnact' : 'btn'"
           v-for="(item, index) in Object.keys(Level1List)" v-bind:key="index">
        {{ item }}
      </div>
    </div>
    <div class="do-fun">
      <div class="level2">
        <div @click="toggleL2List(index)" :class="actLevel2 == index ? 'act' : ''"
             v-for="(item, index) in Level1List[active]" v-bind:key="index">
          {{ item }}
        </div>
      </div>
      <div class="list-area">
        <div v-if="active == '销售合同' && Level1List[active][actLevel2] == '合同清单' ">
          <Xiangmu/>
        </div>
        <div v-else-if="active == '客户管理' && Level1List[active][actLevel2] == '客户信息' ">
          <Kh/>
        </div>
        <div v-else-if="active == '销售订单' && Level1List[active][actLevel2] == '订单列表' ">
          <Dingdan/>
        </div>

        <div v-else>
          <div class="btns">
            <div class="list-title">{{ Level1List[active][actLevel2] }}</div>
            <div>
              <div class="fun-btn">新增</div>
              <div class="fun-btn">编辑</div>
              <div class="fun-btn">删除</div>
            </div>
          </div>
          <div class="nodata">
            <img src="../assets/images/bgimg/notfund.svg" alt="">
            <div style="font-size: 1.2rem">待添加数据</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import Xiangmu from './jishu/Xiangmu.vue';
import Kh from './xiaoshou/Kh.vue';
import Dingdan from './xiaoshou/Dingdan.vue';

export default {
  components: {
    Xiangmu,
    Kh,
    Dingdan
  },
  props: {
    listIndex: {
      type: Number,
      default: 1
    },
    admin: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      active: '',
      actLevel2: 0,
      Level1List: {
        "销售合同": [
          "合同清单",
        ],
        "客户管理": [
          "客户信息",
        ],
        "销售订单": [
          "订单列表",
        ],
      }
    };
  },
  mounted() {
      this.active = "销售合同";
  }
  ,
  methods: {
    toggleMainList(name) {
      this.active = name;
      this.actLevel2 = 0; // 重置二级菜单的选中状态
    }
    ,
    toggleL2List(index) {
      this.actLevel2 = index;
    }
  }
}
</script>

<style lang="scss" scoped>
/* Add any additional styles here */
.level1 {
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn, .btnact {
    cursor: pointer;
    background: url("../assets/images/bgimg/title1.png") no-repeat;
    background-size: 100% 100%;
    color: #ccc;
    padding: 0.5vw 2vw;
    font-weight: bold;
    letter-spacing: 3px;
    margin-right: 2vw;
  }

  .btnact {
    background: url("../assets/images/bgimg/title1act.png") no-repeat;
    background-size: 100% 100%;
  }

  .btn:hover {
    color: #fff;
  }
}

.do-fun {
  display: flex;
  padding: 5vh 10vw;
  color: #fff;

  .level2 {
    width: 15vw;

    > div {
      cursor: pointer;
      width: 9vw;
      margin-bottom: 2.5vh;
      padding: 1.2vh 0 1.2vh 2rem;
      background: url("../assets/images/bgimg/title2.png") no-repeat;
      background-size: 100% 100%;
      font-weight: bold;
      color: #ccc;
    }

    > div:hover {
      color: #fff;
    }

    .act {
      color: #fff;
    }
  }

  .list-area {
    flex: 1;
    background: url("../assets/images/bgimg/mainlistbg.png") no-repeat;
    background-size: 100% 100%;
    height: 60vh;
    padding: 2vw;
    overflow: hidden;
    .btns {
      display: flex;
      justify-content: space-between;

      .list-title {
        font-size: 1.5rem;
      }
    }

    .nodata {
      text-align: center;
      padding-top: 10vh;

      img {
        width: 5vw;
      }
    }

    .fun-btn {
      cursor: pointer;
      display: inline-block;
      background: url("../assets/images/bgimg/funbtn.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 1vw;
      padding: 0.2vw 1vw;
    }
  }
}
</style>
