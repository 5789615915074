<template>
  <div id="app">
    <div class="top-menu">
      <div class="top-lr top-l"> V0.5.13</div>
      <div class="top-mid" @click="changeUi">泽田药业智慧管理系统</div>
      <div class="top-lr top-r">北京时间： {{ currentTime }}
        <el-popover
            placement="bottom"
            trigger="click"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
          <el-table :data="emailData">
            <el-table-column width="150" property="title" label="标题"></el-table-column>
            <el-table-column width="100" property="message" label="内容"></el-table-column>
          </el-table>
          <el-badge  slot="reference" :value="emailData.length" class="item">
            <img src="./assets/images/icons/email.svg" alt="">
          </el-badge>
        </el-popover>
        <el-popover
            placement="top"
            width="160">
          <p>现在是{{ admin?'超级管理员': '普通用户'}}，点击下方切换角色</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="changeUser(1)">普通用户</el-button>
            <el-button type="text" size="mini" @click="changeUser(0)">超级管理员</el-button>
          </div>
          <img slot="reference" src="./assets/images/icons/my.svg" alt="">
        </el-popover>


      </div>
    </div>
    <div class="main-menu" v-if="showMenu">
      <video  ref="videoPlayer" autoplay muted loop  src="./assets/video/mainbg.mp4"></video>
      <div class="main-shadow"></div>
      <div v-if="!showList" >
        <div class="logo">
          <img src="./assets/images/logo.png" alt="">
        </div>
        <div class="menu-1">
          <div class="main-btn" @click="openList(3)"> <div>行 政<div class="en">Administrative</div></div></div>
          <div class="main-btn" @click="changeUi"> <div>首 页<div class="en">Home</div></div></div>
          <div class="main-btn" @click="openList(1)">
            <div>技 术<div class="en">Technology</div></div>

          </div>
        </div>
        <div class="menu-2">
          <div class="main-btn" @click="openList(4)"> <div>采 购<div class="en">Purchase</div></div></div>
          <div></div>
          <div class="main-btn" @click="openList(5)"> <div>销 售<div class="en">Sale</div></div></div>
        </div>
        <div class="menu-1">
          <div class="main-btn" @click="openList(6)"> <div>资 管<div class="en">Asset Management</div></div></div>
          <div class="main-btn" @click="openList(7)"> <div>财 务<div class="en">Finance</div></div></div>
          <div class="main-btn" @click="openList(8)"> <div>数据分析<div class="en">Data Analysis</div></div></div>
        </div>
      </div>
      <div v-else class="menu-list">

          <ListData2 v-if="listIndex==1" :list-index="listIndex" :admin="admin"/>
          <ListData3 v-else-if="listIndex==4" :list-index="listIndex" :admin="admin"/>
          <ListData4 v-else-if="listIndex==5" :list-index="listIndex" :admin="admin"/>
          <ListData5 v-else-if="listIndex==6" :list-index="listIndex" :admin="admin"/>
          <ListData6 v-else-if="listIndex==7" :list-index="listIndex" :admin="admin"/>
          <ListData7 v-else-if="listIndex==8" :list-index="listIndex" :admin="admin"/>
          <ListData1 v-else :list-index="listIndex" :admin="admin"/>
        <div class="back">
          <div class="back-btn" @click="showList=false">返回主菜单</div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-drawer
          title="我是标题"
          :visible.sync="drawer"
          :with-header="false"
          :direction="direction"
          :before-close="handleClose"
          class="custom-drawer">
        <div style="background-color:#102455;">
          <RepMag/>
        </div>
      </el-drawer>
      <el-drawer
          title="我是标题"
          :visible.sync="drawer2"
          :with-header="false"
          :direction="'rtl'"
          :before-close="handleClose"
          class="custom-drawer">
        <ProdSys/>
      </el-drawer>
      <threeUI v-if="!showLib" class="threeUI"/>
      <ThreeLib v-else class="threeUI"/>
<!--开始-->
      <div v-if="showLib==true">
      <div class="number-data">
        <div class="nd-l">
          <div >
            <PieChart class="need-event"/>
            <div style="color: #fff;text-align: center">项目阶段占比</div>
          </div>
          <div>
            <ProgressBar
                title="Task Progress"
                :current="current"
                :total="total"
            />
          </div>
        </div>
        <div class="nd-mid">
          <!--        中间-->
          <div @click="showMenu=false" class="three-btn">首页</div>
          <div @click="showMenu=true" class="three-btn">办公</div>
        </div>
        <div class="nd-r">
          <div class="news-list">
            <div>
              <div>
                <img src="./assets/images/icons/success.svg" alt="">
                P202005 处方工艺接近尾声
              </div>
              <div>
                24-05-15 14:52
              </div>
            </div>
            <div>
              <div>
                <img src="./assets/images/icons/warning.svg" alt="">
                P202208 已完成未定型6个月，准备提交申报资料
              </div>
              <div>
                24-05-15 14:50
              </div>
            </div>
            <div>
              <div>
                <img src="./assets/images/icons/error.svg" alt="">
                P202314 处方研究过程中
              </div>
              <div>
                24-05-15 14:50
              </div>
            </div>
            <div>
              <div>
                <img src="./assets/images/icons/success.svg" alt="">
                P202107 已完成工艺验证，稳定性实验进行中
              </div>
              <div>
                24-05-15 14:50
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mid-data">
        <div class="mid-l">
          <div class="main-title">药品稳定性试验箱运行数据</div>
          <auto-race/>
        </div>
        <div class="mid-r">
          <div class="main-title" @click="drawer2 = true">在研实验进度</div>
          <div class="schedule-list">
            <div class="item">
              <div class="pro-box">
                <progress-ring style="margin: 0 auto;" :total-progress="100" :current-progress="45"
                               :progress-color="'#fcb223'"></progress-ring>
              </div>
              <img class="pro-bg" src="./assets/images/bgimg/probg.png" alt="">
              <div class="goods-title">P202403 </div>
              <div class="target-data">工艺路线进行中</div>
              <div class="target-data">下一阶段 进行申报</div>
            </div>
            <div style="width: 10px">
              <img src="./assets/images/bgimg/gun.png" style="height: 100%" alt="">

            </div>
            <div class="item">
              <div class="pro-box">
                <progress-ring style="margin: 0 auto;" :total-progress="100" :current-progress="31"
                               :progress-color="'#ff0000'"></progress-ring>
              </div>
              <img class="pro-bg" src="./assets/images/bgimg/probg.png" alt="">
              <div class="goods-title">P202310</div>
              <div class="target-data">工艺验证进行中</div>
              <div class="target-data">下一阶段 进行稳定性实验</div>

            </div>
          </div>

        </div>
      </div>

      <div class="bottom-data">
        <div>
          <div class="main-title">实时温度检测</div>
          <TempChart :data="temperatureData"/>
        </div>
        <div>
          <div class="main-title">每日高低温记录</div>
          <CapChart/>
        </div>
      </div>
      </div>

<!--      结束-->
      <div v-else>
        <div class="number-data">
          <div class="nd-r">
            <div style="display: flex;padding:2vh;align-items: center;justify-content: center">
              <img style="width:15%"  src="./assets/images/icons/meeting.svg" alt="">
              <div style="height: 100%;color: #fff">
                <h2>访客指引</h2>
                <h3>泽田药业智慧管理系统报告会</h3>
                <h4>会议时间: 2024年*月*日 10:00-12:00</h4>
                <h4>会议地点: 2楼会议室A</h4>
              </div>
            </div>

          </div>
          <div class="nd-mid">
            <!--        中间-->
            <div @click="showLib=false" class="three-btn">首页</div>
            <div @click="showMenu=true" class="three-btn">办公</div>
          </div>
          <div class="nd-l">
            <div style="text-align: center;">
              <animated-number style="margin-top: 1vh" :initialValue="695" />
              <div style="color: #fff;text-align: center;margin-top: 1vh">入场职工</div>
            </div>
            <div style="text-align: center;">
              <animated-number style="margin-top: 1vh" :initialValue="16" />
              <div style="color: #fff;text-align: center;margin-top: 1vh">接待访客</div>
            </div>
          </div>

        </div>
        <div class="mid-data">
          <div class="mid-l">
            <div class="main-title">大楼监控</div>
            <el-row :gutter="20" style="margin: 20px 0">
            <el-col :span="12"><div class="grid-content bg-purple"> <video ref="video" src="./assets/video/line1.mp4" autoplay muted loop></video><div>生产线阶段1</div></div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple"> <video ref="video" src="./assets/video/line2.mp4" autoplay muted loop></video><div>生产线阶段2</div></div></el-col>
            </el-row>
            <el-row :gutter="20"  style="margin: 20px 0">
              <el-col :span="12"><div class="grid-content bg-purple"> <video ref="video" src="./assets/video/lib1.mp4" autoplay muted loop></video><div>药物实验室1</div></div></el-col>
              <el-col :span="12"><div class="grid-content bg-purple"> <video ref="video" src="./assets/video/lib2.mp4" autoplay muted loop></video><div>药物实验室2</div></div></el-col>
            </el-row>
          </div>
          <div class="mid-r">
            <div class="main-title" @click="drawer2 = true">区域数据</div>
            <FloorTreemap />

          </div>
        </div>
        <div class="bottom-data">
          <div>
            <div class="main-title">同比产能</div>
            <factory-capacity-chart></factory-capacity-chart>
          </div>
          <div>
            <div class="main-title">营收统计</div>
            <revenue-bar-chart></revenue-bar-chart>
          </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import threeUI from './components/ThreeUI.vue'
import ThreeLib from './components/ThreeLib.vue'
import PieChart from './components/PieChart.vue';
import ProgressRing from './components/ProgressRing.vue';
import AutoRace from './components/AutoRace.vue';
import TempChart from './components/TempChart.vue';
import CapChart from './components/CapChart.vue';
import ProgressBar from './components/ProgressBar.vue';
import RepMag from './components/RepMag.vue';
import ProdSys from './components/ProdSys.vue';
import ListData1 from './components/ListData1.vue';
import ListData2 from './components/ListData2.vue';
import ListData3 from './components/ListData3.vue';
import ListData4 from './components/ListData4.vue';
import ListData5 from './components/ListData5.vue';
import ListData6 from './components/ListData6.vue';
import ListData7 from './components/ListData7.vue';
import FloorTreemap from './components/FloorTreemap.vue';
import AnimatedNumber from './components/AnimatedNumber.vue';
import FactoryCapacityChart from './components/FactoryCapacityChart.vue'
import RevenueBarChart from './components/RevenueBarChart.vue'

export default {
  name: 'App',
  components: {
    threeUI,
    ThreeLib,
    PieChart,
    ProgressRing,
    AutoRace,
    TempChart,
    CapChart,
    ProgressBar,
    RepMag,
    ProdSys,
    ListData1,
    ListData2,
    ListData3,
    ListData4,
    ListData5,
    ListData6,
    ListData7,
    FloorTreemap,
    AnimatedNumber,
    FactoryCapacityChart,
    RevenueBarChart
  },
  data() {
    return {
      listIndex:1,
      showMenu: false,
      showList:false,
      drawer: false,
      drawer2: false,
      admin:true,
      showCanvas: true,
      direction: 'ltr',
      currentTime: '',
      temperatureData: [
        {name: 'ICPMS仪器室', baseTemp: 26, currentTemp: 18},
        {name: 'ICP仪器室', baseTemp: 28, currentTemp: 30},
        {name: '气质室', baseTemp: 25, currentTemp: 21},
        {name: '微生物室', baseTemp: 20, currentTemp: 22},
        {name: '液质室', baseTemp: 22, currentTemp: 19},
        {name: '制剂室', baseTemp: 24, currentTemp: 17},
        {name: '质量部', baseTemp: 22, currentTemp: 26},
      ],
      emailData:[],
      current: 98, // 直接设置初始值
      total: 100,
      showLib: true
    }
  },
  mounted() {
    this.updateTime();
    // this.sendEmail();
    setInterval(this.updateTime, 1000);
    // setInterval(this.sendEmail, 60000);
    this.$refs.videoPlayer.playbackRate = 0.5;
  },
  methods: {

    changeUser(index){
      if (index==0){
        this.admin = true;
      }else{
        this.admin = false;
      }
      if( this.showList == true){
        this.showList = false;
      }
    },
    handleClose(done) {
      done();
    },
    sendEmail(){
      const email = {
        title: '新的邮件'+this.emailData.length,
        message: '这是模拟的邮件内容'+this.emailData.length
      }
      this.emailData.push(email)
      this.$notify(email);

    },
    updateTime() {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString();
    },
    openList(index){
      this.showList = true;
      this.listIndex = index
    },
    changeUi(){
      this.showMenu= !this.showMenu
    },
    goToExternalLink() {
      window.open('http://www.jiuruoaceshi.com/', '_blank');
    },
    test() {
      alert('aaa')
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  margin: 0;
  position: relative;
}

@font-face {
  font-family: 'Alimama';
  src: url('@/assets/font/AlimamaShuHeiTi-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.main-menu {
  video {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -9999;
  }
  .main-shadow{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 37, 37, 0.3); /* 半透明背景 */
    backdrop-filter: blur(20px); /* 背景模糊 */
    -webkit-backdrop-filter: blur(10px); /* 兼容老版本 Safari */
    z-index: -9998;
  }

  .logo {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: -9997;
    img {
      width: 20vw;
      margin-top: 3vh;
    }
  }

  .menu-1, .menu-2 {
    height: 30vh;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .main-btn {
      cursor: pointer;
      font-family: 'Alimama', sans-serif;
      background: url("./assets/images/bgimg/maintitlegifbg.png") no-repeat;
      background-size: 100% 100%;
      width: 24%;
      height: 18vh;
      margin: 0 8%;
      font-size: 1.3vw;
      font-weight: bold;
      text-align: center;
      color: #acacac;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .en{
        font-size: 0.9vw;
        line-height: 1.2vw;
      }
    }

    .main-btn:hover {
      color: #fff;
      background: url("./assets/images/bgimg/maintitlegif.gif") no-repeat;
      background-size: 100% 100%;
    }
  }

  .menu-2 {
    > div {
      width: 24%;
      height: 18vh;
      margin: 0 8%;
    }
  }
}

.custom-drawer .el-drawer__body {
  background-color: #12142b !important;
}

.top-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5%;
  font-weight: bold;
  color: #23cefd;

  .top-mid {
    text-align: center;
    width: 50%;
    height: 6vh;
    background: url("./assets/images/bgimg/topbg.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 1.5vh;
    font-size: 0.8vw;
  }

  .top-l {
    padding-left: 2vw;
    text-align: left;
    background: url("./assets/images/bgimg/top-l.png") no-repeat;
  }

  .top-r {
    padding-right: 2vw;
    justify-content: end;
    background: url("./assets/images/bgimg/top-r.png") no-repeat;
    img{
      width: 2rem;
      margin-left: 10px;
    }
  }

  .top-lr {
    width: 22%;
    height: 2.5vh;
    display: flex;
    align-items: center;
    background-size: 100% 100%;
  }
}

.number-data {
  display: flex;
  justify-content: space-between;
  padding: 0 2.5%;
  height: 14vh;

  > div {
    width: 30%;
    height: 100%;
  }

  .nd-l {
    display: flex;
    justify-content: space-between;
    > div {
      width: 48%;
      height: 10vh;
      padding: 2vh 0;
      background: url("./assets/images/bgimg/ndbg.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .nd-mid {
    display: flex;
    align-items: start;
    justify-content: center;

    .three-btn {
      cursor: pointer;
      margin: 0 0.5vw;
      padding: 1vh 0;
      text-align: center;
      width: 5vw;
      color: #00ccfd;
      background: url("./assets/images/bgimg/buttonbg.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .nd-r {
    height: 100%;
    overflow: hidden;
    background: url("./assets/images/bgimg/ndbg-r.png") no-repeat;
    background-size: 100% 100%;

    .news-list {
      padding: 1vw;

      > div {
        padding-bottom: 0.3vw;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff
      }
    }

    img {
      width: 1vw;
      margin-right: 1vw;
    }
  }
}

.mid-data {
  position: fixed;
  width: 95%;
  display: flex;
  margin-top: 2vh;
  height: 40vh;
  justify-content: space-between;
  padding: 0 2.5%;
  pointer-events: none;

  > div {

    padding: 2vh;
    width: 28%;
    height: 36vh;
    background: url("./assets/images/bgimg/mid-bg.png") no-repeat;
    background-size: 100% 100%;
  }

  .mid-l {
    color: #00ccfd;
    pointer-events: auto;
    video{
      width: 90%;

      opacity: 0.5;
      transition: opacity 0.3s;
    }
    video:hover{
      cursor: pointer;
      opacity: 1;
    }
  }

  .mid-r {
    pointer-events: auto;
    .schedule-list {
      padding: 2vh 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item {
        flex: 1;
        text-align: center;

        .pro-box {
          width: 55%;
          aspect-ratio: 1 / 1;
          margin: 0 auto
        }

        .pro-bg {
          width: 40%;
          margin-top: -2vh;
        }

        .goods-title {
          color: #fff;
          font-weight: bold;
          margin-top: 0.5vh;
          font-size: 0.7vw;
        }

        .target-data {
          margin-top: 0.7vh;
          color: #fff;
          font-size: 0.7vw;

          .number {
            color: #23cefd;
            font-size: 1vw;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.main-title {
  background: url("./assets/images/bgimg/titlebg.png") no-repeat;
  background-size: 100% 100%;
  color: #00ccfd;
  display: inline-block;
  padding: 0.5vh 5vw 0.5vh 0.8vw;
  cursor: pointer;
  pointer-events: auto;
}

.bottom-data {
  position: fixed;
  pointer-events: none;
  margin-top: 45vh;
  padding: 0 2.5%;
  display: flex;
  justify-content: space-between;
  height: 30vh;
  width: 95%;

  > div {
    width: 48%;
    height: 26vh;
    padding: 2vh;
    background: url("./assets/images/bgimg/btm-bg.png") no-repeat;
    background-size: 100% 100%;
  }
}

.need-event {
  pointer-events: auto !important;
}

.threeUI {
  position: absolute;
  top: 0;
  z-index: -99;
  height: 100vh;
  width: 100vw;
}
.back{
  text-align: center;
  .back-btn{
    display: inline-block;
    background: url("./assets/images/bgimg/backbtnbg.png") no-repeat;
    background-size: 100% 100%;
    font-size: 1.3rem;
    color: #ccc;
    padding: 1vh 2vw;
  }
  .back-btn:hover{
    cursor: pointer;
    color: #fff;
  }
}

</style>
